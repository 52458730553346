import React, { Suspense } from 'react'
import DashboardHeader from '../../dashboardHeader/DashboardHeader'
import AdminSidebar from '../../sidebar/AdminSidebar'

function AdminDashboard({ children }) {

    return (
        <>
            <DashboardHeader />
            <div className="d-flex dashboard" >
                <AdminSidebar />
                <div className="flex-grow-1 main_box" >
                    <Suspense
                    >
                        <div className="middle-sec">
                            {children}
                        </div>
                    </Suspense>
                </div>
            </div>
        </>
    )
}

export default AdminDashboard
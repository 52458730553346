
import axios from "axios";
const _FetchApi = (method, path, body, header) => {
    return new Promise((resolve, reject) => {
        _handleMethod(method, path, body, header)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject(response.data);
                }
            })
            .catch((error) => {
                console.error("An error occurred:", error);
                reject(error);
            });
    });
};

function _handleMethod(method, path, body, header) {
    const headers = changeHeaders(header);
    if (method === "POST") {
        return axios({
            method: "POST",
            url: path,
            data: body,
            headers: headers,
        });
    } else {
        return axios({
            method: "GET",
            url: path,
            headers: headers,
        });
    }
}

function changeHeaders(header) {
    return {
        ...header,
    };
}

export const _FetchApiToken = (method, path, body, header, token) => {
    return new Promise((resolve, reject) => {
        _handleMethodToken(method, path, body, header, token)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject(response.data);
                }
            })
            .catch((error) => {
                console.error("An error occurred:", error);
                reject(error);
            });
    });
};

function _handleMethodToken(method, path, body, header) {
    const token = localStorage.getItem('access');
    const headers = changeHeadersToken(header, token);
    if (method === "POST") {
        return axios({
            method: "POST",
            url: path,
            data: body,
            headers: headers,
        });
    } else {
        return axios({
            method: "GET",
            url: path,
            headers: headers,
        });
    }
}

function changeHeadersToken(header, token) {
    return {
        ...header,
        Authorization: `Bearer ${token}`,
    };
}

export default _FetchApi


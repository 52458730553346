

import { toast } from "react-toastify";
import { BASE_URL } from "../constant/common";
import _FetchApi, { _FetchApiToken } from "./fetchservice";
import axios from "axios";

// let userAuthId = localStorage.getItem('id')
let user_id = null
if (localStorage.getItem('admin_client_id') !== null) {
    user_id = localStorage.getItem('admin_client_id');
} else {
    user_id = localStorage.getItem('id');
}

export const PostCategoriesData = async (list_name, inputRows, userId) => {
    try {
        let data = {
            // user_id: parseInt(userId),
            Category_list_name: list_name,
            sub_categories: inputRows
        };
        const response = await _FetchApiToken("POST", BASE_URL + "categories/", data, {});
        return response.data;

    } catch (error) {
        throw error;
    }
};



export const GetCategoriesData = async (id) => {
    try {
        let response = await _FetchApiToken("get", BASE_URL + `categories`)
        return response.data
    } catch (error) {
        throw error
    }
}


export const GetCategoryonSelect = async (id) => {
    try {
        let response = await _FetchApiToken("get", BASE_URL + `categories/${id}`)
        return response.data
    } catch (error) {
        throw error
    }
}


export const GetUserClient = async () => {
    try {
        let response = await _FetchApi("get", BASE_URL + `usertype_get`)
        return response
    } catch (error) {
        throw error
    }
}

export const PostSendInvite = async (user_id, data) => {
    try {
        const response = await _FetchApi("POST", BASE_URL + `invite_user/?user_id=${user_id}`, data, {});
        return response.data;

    } catch (error) {
        console.log('testing123')
        throw error;
    }
};


export const PostPurchaseSubscription = async (id, data) => {
    try {
        const response = await _FetchApi("POST", BASE_URL + `purchase_subscription/?user_auth_id=${id}`, data, {});
        return response.data;

    } catch (error) {
        throw error;
    }
};


export const GetAccountantPurchasePlan = async (id) => {
    try {
        let response = await _FetchApi("get", BASE_URL + `subscription_all/`)
        return response.data
    } catch (error) {
        throw error
    }
}

export const GetCreateTax = async (user_auth_id) => {
    const token = localStorage.getItem('access');

    try {
        let response = await _FetchApiToken("get", BASE_URL + `tax/?user_auth_id=${user_auth_id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        return response.data
    } catch (error) {
        throw error
        toast.error(error.message || "An error occurred while fetching tax information.");
    }
}

export const GetTax = async (user_auth_id, typeCode) => {

    try {
        let response = await _FetchApiToken("get", BASE_URL + `tax/?user_auth_id=${user_auth_id}&type_code=${typeCode}`)
        return response.data
    } catch (error) {
        throw error
        toast.error(error.message || "An error occurred while fetching tax information.");
    }
}

export const PostNotificationAlert = async (data) => {
    try {
        const res = await _FetchApiToken("POST", BASE_URL + `notification/`, data, {});
        return res;
    } catch (err) {
        return err;
    }
}

export const GetNotificationAlert = async (id) => {
    try {
        let response = await _FetchApiToken("get", BASE_URL + `notification/${id}`)
        return response.data
    } catch (error) {
        throw error
    }
}

export const PostSystemSetting = async (data) => {
    try {
        const res = await _FetchApiToken("POST", BASE_URL + `system_settings/`, data, {});
        return res;
    } catch (err) {
        return err;
    }
}

export const GetSystemSetting = async (id) => {
    try {
        const res = await _FetchApiToken("GET", BASE_URL + `system_settings/?user_id=${id}`);
        return res;
    } catch (err) {
        return err;
    }
}

export const PostAddDocument = async (data, userId) => {
    try {
        const res = await _FetchApiToken("POST", BASE_URL + `upload_document/?user_auth_id=${userId}`, data, {});
        return res;
    } catch (err) {
        return err;
    }
}



export const GetCountryApiInAccounsetupPage = async () => {
    try {
        let response = await _FetchApi("get", BASE_URL + `country_get/`)
        return response.data
    } catch (error) {
        throw error
    }
}


export const GetCityApiInAccounsetupPage = async (id) => {
    try {
        let response = await _FetchApi("get", BASE_URL + `city_country_get/?country_id=${id}`)
        return response.data
    } catch (error) {
        throw error
    }
}



export const GetDocumentList = async (page, userId, status, type) => {
    try {
        const res = await _FetchApiToken("GET", BASE_URL + `upload_document/?user_auth_id=${userId}&status=${status}&type=${type}&page=${page}`);
        return res.data;
    } catch (err) {
        return err;
    }
}

export const PostApprovalDocumentList = async (documentId, data) => {
    try {
        const res = await _FetchApi("POST", BASE_URL + `upload_document_status/?document_id=${documentId}`, data, {});
        return res.data;
    } catch (err) {
        return err;
    }
}

export const PostSyncedInvoice = async (invoiceId, data) => {
    try {
        const res = await _FetchApi("POST", BASE_URL + `resync-invoice/${invoiceId}/`, data, {});
        return res.data;
    } catch (err) {
        return err;
    }
}

export const DeleteDocumentList = async (documentId) => {
    try {
        const res = await axios.delete(BASE_URL + `upload_document_status/?document_id=${documentId}`);
        return res.data;
    } catch (err) {
        return err;
    }
}


export const GetProfileUpdate = async (id) => {
    try {
        let response = await _FetchApiToken("get", BASE_URL + `getprofile/?user_id=${id}`)
        return response.data
    } catch (error) {
        throw error
    }
}


export const GetDetailsDocument = async (id) => {
    try {
        let response = await _FetchApi("get", BASE_URL + `get_detail/?doc_id=${id}`)
        return response.data
    } catch (error) {
        throw error
    }
}

export const GetSupplierCustomerList = async (id, type) => {
    try {
        let response = await _FetchApiToken("get", BASE_URL + `get_supplier_customer/?user_auth_id=${id}&type=${type}`)
        return response.data
    } catch (error) {
        throw error
    }
}


export const GetPdfCsvData = async (id) => {
    try {
        let response = await _FetchApi("get", BASE_URL + `get_all_doc/?user_auth_id=${id}`)
        return response.data
    } catch (error) {
        throw error
    }
}

export const UpdateSupplierCustomer = async (data) => {
    const token = localStorage.getItem('access');

    try {
        let response = await axios.put(BASE_URL + `get_supplier_customer/`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        return response.data
    } catch (error) {
        throw error
    }
}

export const MergeSupplierCustomer = async (data) => {
    try {
        let response = await axios.post(BASE_URL + `mergesuplirecustomer/`, data)
        return response.data
    } catch (error) {
        throw error
    }
}

export const DeleteSupplierCustomer = async (id) => {
    const token = localStorage.getItem('access');

    try {
        const res = await axios.delete(BASE_URL + `get_supplier_customer/?supplier_customer_id=${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return res.data;
    } catch (err) {
        return err;
    }
}

export const PostXeroUser = async (data) => {
    try {
        const res = await _FetchApiToken("POST", BASE_URL + `xero/check_Xero_connected/`, data, {});
        return res.data;
    } catch (err) {
        return err;
    }
}

export const GetXeroCreateInvoice = async (userId) => {
    try {
        let response = await _FetchApi("get", BASE_URL + `xero/create_invoice/${userId}`)
        return response.data
    } catch (error) {
        throw error
    }
}

export const PostSubscribePlan = async (data) => {
    try {
        const res = await _FetchApi("POST", BASE_URL + `subscribePlan/`, data, {});
        return res.data;
    } catch (err) {
        return err;
    }
}

export const GetAllInvoice = async (userId) => {
    try {
        let response = await _FetchApi("get", BASE_URL + `get_all_invoice/?user_id=${userId}`)
        return response.data
    } catch (error) {
        throw error
    }
}

export const PostCancelSubscribePlan = async (data) => {
    try {
        const res = await _FetchApi("POST", BASE_URL + `cancel_subscription/`, data, {});
        return res.data;
    } catch (err) {
        return err;
    }
}

export const PostInviteTeamMember = async (data) => {

    try {
        const res = await _FetchApiToken("POST", BASE_URL + `invite_team_member/`, data, {});
        return res.data;
    } catch (err) {
        return err;
    }
}

export const PostUpdateTeamMemberPassword = async (data) => {
    try {
        const res = await _FetchApi("POST", BASE_URL + `update_team_member_password/`, data, {});
        return res.data;
    } catch (err) {
        return err;
    }
}

export const GetInviteTeamMember = async (userId) => {
    try {
        let response = await _FetchApiToken("get", BASE_URL + `invite_team_member/?user_id=${userId}`)
        return response.data
    } catch (error) {
        throw error
    }
}

import React from 'react'
import PageNotFoundImage from "../../assets/images/PageNotFoundImage.png"
import { TiArrowLeft } from "react-icons/ti";
import './PageNotFound.scss'
import LoginSignupHeader from '../loginSignupHeader/LoginSignupHeader';
import { useNavigate } from 'react-router';

export default function PageNotFound() {
  
  const navigate = useNavigate()
  const handlebackbutton = ()=>{
    navigate('/login')
  }
  
  return (
    <>
      <LoginSignupHeader />
      <div className='pageNotFound_container'>
        <div className='d-flex  pageNotFound_content'>
          <div>
            <h3>Oops.... </h3>
            <h4>Page not found</h4>
            <p>This Page doesn`t exist or was removed! <br />
              We suggest you  back to home.</p>
            <button className='backToHome_btn' onClick={handlebackbutton}><span><TiArrowLeft /></span>Back to home</button>
          </div>
          <div>
            <img src={PageNotFoundImage} alt="Page not found" />
          </div>
        </div>

      </div>


    </>
  )
}



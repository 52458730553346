
import React, { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import budget from "../../assets/images/budget.png";
import "./../login/login.scss";
import { Link, useNavigate } from 'react-router-dom';
import google from './../../assets/images/google.png';
import LoginSignupHeader from '../loginSignupHeader/LoginSignupHeader';
import { useDispatch } from 'react-redux';
import { userLoginFailure } from '../../redux/actions';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { BASE_URL } from '../../constant/common';
import _FetchApi from '../../services/fetchservice';
import { toast } from 'react-toastify';

const Login = () => {
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleNewPasswordVisibility = () => {
        setNewPasswordVisible((prev) => !prev);
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email address')
            .required('*required'),
        password: Yup.string()
            .required('*required')
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onLogin(values);
        }
    });

    const onLogin = async (values) => {
        try {
            const { email, password } = values
            const newData = { email, password }
            const response = await _FetchApi("POST", `${BASE_URL}login/`, newData, {
                "Content-Type": "application/json",
            });

            let code = response?.data?.code;

            if (code === 400) {
                toast.error("Invalid credentials");
            } else {
                let token = response?.data?.message?.token?.access
                if (token) {
                    localStorage.setItem('access', token)
                    localStorage.setItem('id', response?.data?.message?.user_data.id)

                    let message = response?.data?.message
                    let userName = response?.data?.message?.user_profile?.first_name
                    let userLastName = response?.data?.message?.user_profile?.last_name
                    let email = response?.data?.message?.user_profile?.email
                    let is_client = response?.data?.message?.user_data?.is_client
                    let phone_number = response?.data?.message?.user_profile?.phone_number
                    let country_code = response?.data?.message?.user_profile?.country_id?.country_code
                    let country_flag = response?.data?.message?.user_profile?.country_id?.country_flag
                    let country_name = response?.data?.message?.user_profile?.country_id?.country_name
                    let created_date = response?.data?.message?.user_profile?.country_id?.created_date
                    let last_updated_date = response?.data?.message?.user_profile?.country_id?.last_updated_date
                    let Organisation = response?.data?.message?.user_data?.Tbl_Organization_M_id?.organization_domain
                    let userType = response?.data?.message?.user_data.Tbl_User_Type_id

                    if (token) {
                        localStorage.setItem('message', JSON.stringify(message))
                        localStorage.setItem('userName', userName)
                        localStorage.setItem('userLastName', userLastName)
                        localStorage.setItem('email', email)
                        localStorage.setItem('is_client', is_client)
                        localStorage.setItem('phone_number', phone_number)
                        localStorage.setItem('country_code', country_code)
                        localStorage.setItem('country_name', country_name)
                        localStorage.setItem('country_flag', country_flag)
                        localStorage.setItem('created_date', created_date)
                        localStorage.setItem('last_updated_date', last_updated_date)
                        localStorage.setItem('Organisation', Organisation)
                        localStorage.setItem('user_type', JSON.stringify(userType))
                        // console.log('usertype',userType.user_type,is_client,userType.user_type === 3)
                        if (is_client || userType.user_type === 3) {
                            navigate('/dashboard');
                        } else {
                            navigate('/accountant-dashboard');
                        }
                    }
                }
            }
        } catch (error) {
            dispatch(userLoginFailure(''));
        }
    };


    return (
        <>
            <LoginSignupHeader />
            <div className='main_section'>
                <div className="row g-0">
                    <div className="col-lg-6 col-12 loginForm" style={{ marginTop: "20px" }}>
                        <h2 className="heading-log">Experience stress-free bookkeeping solutions tailored for your business.</h2>
                        <div className='Loginform'>
                            <form className='login' onSubmit={formik.handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label labels_form" style={{ marginTop: "28px" }}>Email address</label>
                                    <input
                                        type="email"
                                        className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`} style={{ height: "40px", color: "black" }}
                                        id="exampleInputEmail1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} is-invalid
                                        value={formik.values.email}
                                        placeholder='Enter your email address'
                                        name="email"
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="error-msg">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputPassword1" className="form-label labels_form">Password</label>
                                    <div className='position-relative' style={{ maxWidth: "100%" }}>
                                        <input
                                            type={newPasswordVisible ? 'text' : 'password'}
                                            name="password"
                                            id="new-password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                            placeholder="Enter your password"
                                            className={`form-control ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
                                        />
                                        <button type="button" onClick={toggleNewPasswordVisibility} className='position-absolute border-0 bg-transparent d-flex' style={{ top: "30px", right: "10px", transform: "translateY(-19px)", color: "#828282", fontSize: "20px" }}>
                                            {!newPasswordVisible ? <FiEyeOff /> : <FiEye />}
                                        </button>
                                        {formik.touched.password && formik.errors.password ? (
                                            <div className="error-msg">{formik.errors.password}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <button type='submit' className=" mt-3 form-control login-btn">Login</button>
                                <Link to='/forgotpassword' style={{ textDecoration: 'none' }}>
                                    <a href="" className='text-decoration-none forgot_passwords'>Forgot password?</a>
                                </Link>
                                <div className="d-grid gap-2 mt-3 mb-2">
                                    <a className="signup-btn google-signup-btn text-center text-decoration-none" type="button"><img src={google} alt="..." className='google_link labels_form' /> Sign up with Google</a>
                                </div>
                                <div className="or-line ">
                                    <div className="col-md-12 orclass position-relative">
                                        or
                                    </div>
                                </div>
                                <Link to='/signup' className='no-text-decoration'>
                                    <button type="submit" className="ctreate-button mt-2 form-control" id="exampleFormControlInput1">
                                        Create a new account
                                    </button>
                                </Link>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6 LoginImageStyle" style={{ marginTop: "0px" }}>
                        <img src={budget} alt="" />
                    </div>
                </div >

            </div >
        </>
    );
}

export default Login;





import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import Add from '../assets/images/Add.png';
import './dashboardheader.scss';
import Ellipse from "../assets/images/Ellipse.png";
import dummyprofile from "../assets/images/dummy-profile.png";
import BookeezLogo from '../assets/images/BookeezLogo.png'
import { useDispatch, useSelector } from 'react-redux';
import { logoutProfile } from '../redux/actions';
import ChooseFilePopup from '../dashboardpage/dashboard/ChooseFilePopup';
import { BASE_URL_IMG } from '../constant/common';
import { GetProfileUpdate } from '../services/api';


const DashboardHeader = (props) => {
    const [showpopup, setshowpopup] = useState(false);
    const [logoutButton, setLogoutButton] = useState(true)
    const [user, setUser] = useState();
    const dispatch = useDispatch()

    const ApiStatus = useSelector((state) => state.fetch.fetchStatus);

    const clientStatus = localStorage.getItem('is_client');

    const data = JSON.parse(localStorage.getItem('message'));
    const first_name = localStorage.getItem('first_name');
    const profile_image = localStorage.getItem('profile_image');

    const p = localStorage.getItem('updated_first');
    const ClientType = JSON.parse(localStorage.getItem('user_type'))?.user_type;

    let user_id = localStorage.getItem('id');
    if (localStorage.getItem('admin_client_id') !== null) {
        user_id = localStorage.getItem('admin_client_id');
    } else {
        user_id = localStorage.getItem('id');
    }

    const handleAddDocumentClick = () => {
        setshowpopup(true);
    };

    const handleLogout = () => {
        localStorage.clear('token');
        setLogoutButton(true);
    }

    const handleLogoutButton = () => {
        setLogoutButton(false);
        dispatch(logoutProfile(false))
    };

    const log = useSelector((state) => state.logout)
    useEffect(() => {
        if (log.logout) {
            setLogoutButton(true);
        }
    }, [log.logout]);

    let userName = localStorage.getItem('userName')
    const adminClientId = localStorage.getItem('admin_client_id');

    const handleRemove = () => {
        let arr = document.querySelectorAll('.accordion-collapse');
        arr.forEach((item, i) => {
            if (i > 0) {
                let acc = document.querySelectorAll('.accordion-collapse')[i];
                let accor = document.querySelectorAll('.accordion-button')[i];
                acc?.classList.remove('show');
                accor?.classList.remove('accordion-button1');
            }
        });
    };

    const path = window.location.pathname;

    const fetchData = async () => {
        try {
            let data = await GetProfileUpdate(user_id);
            const useeData = JSON.stringify(data?.message)
            localStorage.setItem('userData',useeData)
            setUser(data?.message);
        } catch (error) {
            console.error("Error fetching profile update:", error);
        }
    };

    useEffect(() => {
        fetchData();
        let accor = document.querySelectorAll('.accordion-button')[0]
        let acc = document.querySelectorAll('.accordion-collapse')[0]
        let newPath = path?.split('/')

        if (path === '/dashboard') {
            accor?.setAttribute("aria-expanded", true);
        }

        return () => {
            accor?.setAttribute("aria-expanded", true);
        };
    }, [ApiStatus]);

    // useEffect(()=>{

    // },[profile_image])

    return (
        <>
            <div className="header2">
                <div className="logo2 ms-md-3">
                    <Link to="/dashboard" onClick={handleRemove}>
                        <img src={BookeezLogo} alt='BookeezLogo' />
                    </Link>
                </div>
                <div className="user_actions2">
                    {data?.user_data?.is_client || adminClientId || ClientType === 3 ? (
                        <>
                            <button onClick={handleAddDocumentClick}>
                                <span><img src={Add} alt="Add"  /></span> Add documents
                            </button>
                            {showpopup && <ChooseFilePopup openchooseFile={true} closeChooseFile={() => setshowpopup(false)} />}
                        </>
                    ) : ''}

                    {logoutButton ?
                        <>
                            <p className='text-capitalize'>{data?.user_data?.is_client || ClientType === 3 ? '' : 'Welcome accountant'} {user?.first_name || userName}</p>
                            <Link to={data?.user_data?.is_client || ClientType === 3 ? '/dashboard/profilePage' : '/dashboard/accountant/profilePage'} onClick={handleLogoutButton}>
                                <div className="user_photo">
                                    <img
                                        src={user?.profile_image ? `${BASE_URL_IMG}${user?.profile_image}` : dummyprofile}
                                        alt="UserPhoto"
                                        className="rounded-circle"
                                        style={{ width: "40px", height: "40px", objectFit: "cover" }}
                                    />
                                </div>
                            </Link>
                        </>
                        : <Link to='/login' style={{ textDecoration: 'none' }}>
                            <button className='LogoutButtonStyle' onClick={handleLogout}>
                                Logout
                            </button>
                        </Link>
                    }
                </div>
            </div>
        </>
    );
};

export default DashboardHeader;




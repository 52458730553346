import { put, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "./actionTypes";
import _FetchApi from "../services/fetchservice";
import { BASE_URL, VERIFY_EMAIL_URL } from "../constant/common";
import { useNavigate } from "react-router-dom";

function* UserLogin(action) {
  let result = yield _FetchApi("POST", `${BASE_URL}login/`, action.payload);
  let newRes = result["data"].message;
  const navigate = useNavigate();

  try {
    yield put({ type: ActionTypes.LOGIN_SUCCESS, data: newRes });
    if (newRes) {
      const token = newRes.token.access;
      localStorage.setItem("token", token);
      navigate("/dashboard");
    }
  } catch {
    yield put({ type: ActionTypes.LOGIN_FAILURE, data: result });
  }
}

function* ForgotPassword(action) {
  let data = yield _FetchApi("POST", `${BASE_URL}reset_password/`, {
    email: action.payload,
  });

  try {
    yield put({ type: ActionTypes.FORGOT_PASSWORD_SUCCESS, payload: data });
  } catch {
    yield put({ type: ActionTypes.FORGOT_PASSWORD_FAILURE, data: "data" });
  }
}

function* CreateNewPassword(action) {
  const { type, payload } = action;

  let data = yield _FetchApi("POST", `${BASE_URL}set_password/`, payload);

  try {
    yield put({ type: ActionTypes.CREATE_NEW_PASSWORD_SUCCESS, payload: data });
  } catch {
    yield put({ type: ActionTypes.CREATE_NEW_PASSWORD_FAILURE, data: "data" });
  }
}


function* UpdateClientPassword(action) {
  const { type, payload } = action;

  let data = yield _FetchApi("POST", `${BASE_URL}update_client_password/`, payload);
  if(data){
    localStorage.setItem('clientData',JSON.stringify(data))
  }

  try {
    yield put({ type: ActionTypes.UPDATE_PASSWORD_SUCCESS, payload: data });
  } catch {
    yield put({ type: ActionTypes.UPDATE_PASSWORD_FAILURE, data: "data" });
  }
}

function* GetCountry(action) {
  let data = yield _FetchApi("GET", `${BASE_URL}country_get/`);
  try {
    yield put({ type: ActionTypes.GET_COUNTRY_Success, countryData: data });
  } catch {
    yield put({ type: ActionTypes.GET_COUNTRY_Failure, data: data });
  }
}

function* PostSignUp(action) {
  let data;

  try {
    const {
      email,
      password,
      confirm_password,
      country_id,
      first_name,
      last_name,
      organization,
      phone_number,
    } = action.payload;

    const signUpData = {
      email,
      password,
      confirm_password,
      country_id,
      first_name,
      last_name,
      organization,
      phone_number,
    };
    let response = yield _FetchApi("POST", `${BASE_URL}sign_up/`, signUpData, {
      "Content-Type": "application/json",
    });


    if (response.statusText === "ok") {
      yield put({ type: ActionTypes.SIGN_UP_SUCCESS, payload: response });
    } else {
      yield put({
        type: ActionTypes.SIGN_UP_FAILURE,
        payload: response,
      });
    }
  } catch {
  }
}

function* PostVerifyEmail(action) {
  const navigate = useNavigate();
  try {
    const response = yield _FetchApi(
      fetch,
      `${VERIFY_EMAIL_URL}auth/verifyemail/${action.payload.token}`,
      {
        method: "POST",
      }
    );
    const data = yield response.json();

    if (response.ok) {
      yield put({ type: ActionTypes.VERIFY_EMAIL_SUCCESS, payload: data });
    } else {
      yield put({
        type: ActionTypes.VERIFY_EMAIL_FAILURE,
        payload: data.message,
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.VERIFY_EMAIL_FAILURE,
      payload: error.message,
    });
  }
}

function* Saga() {
  yield takeEvery(ActionTypes.GET_COUNTRY_Request, GetCountry);
  yield takeEvery(ActionTypes.VERIFY_EMAIL_REQUEST, PostVerifyEmail);
  yield takeEvery(ActionTypes.SIGN_UP_REQUEST, PostSignUp);
  yield takeEvery(ActionTypes.LOGIN_REQUEST, UserLogin);
  yield takeEvery(ActionTypes.FORGOT_PASSWORD_REQUEST, ForgotPassword);
  yield takeEvery(ActionTypes.CREATE_NEW_PASSWORD_REQUEST, CreateNewPassword);
  yield takeEvery(ActionTypes.UPDATE_PASSWORD_REQUEST, UpdateClientPassword);
}

export default Saga;

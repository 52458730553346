
//Bhagwan- Pricing page start //

import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Row, Col } from 'react-bootstrap';
import "./pricing.scss"
import correctIcon from "../../assets/images/correctIcon.png"
import wrongIcon from "../../assets/images/wrongIcon.png"
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { useLocation } from 'react-router-dom';


const Pricing = () => {
    const { pathname } = useLocation();


    const [isToggled, setIsToggled] = useState('yearly');

    const onToggle = () => {
        setIsToggled((prevCycle) => (prevCycle === 'monthly' ? 'yearly' : 'monthly'));
    };


    const pricingPlans = [
        { name: 'BASIC', priceMonthly: '8.99', priceYearly: '94.99' },
        { name: 'STANDARD', priceMonthly: '14.99', priceYearly: '164.99' },
        { name: 'PREMIUM', priceMonthly: '24.99', priceYearly: '279.99' },
    ];

    const getPrice = (plan) => isToggled === 'monthly' ? plan.priceMonthly : plan.priceYearly;

    const containerStyle = {
        width: '80px',
        height: '35px',
        borderRadius: '25px',
        backgroundColor: isToggled === 'yearly' ? '#1141C8' : '#4cd137',
        position: 'relative',
        cursor: 'pointer',
        transition: 'background-color 300ms',
        backgroundColor: '#328ED9',
        marginTop: "8px"
    };

    const circleStyle = {
        width: '29px',
        height: '29px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        position: 'absolute',
        top: '3px',
        left: isToggled === 'yearly' ? '2.5px' : '48.5px',
        transition: 'left 300ms',
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className='pricing_container'>
            <Header />
            <Container className='Cardscontainer '>
                <Row className="justify-content-center my-4">
                    <p className='ContainerTitle mt-3 mb-3' style={{ maxWidth: "44ch" }}>Find the perfect plan for you. Choose the subscription that suits your needs best.</p>
                    <div className='ToggleButtonstyle'>
                        <div className='center-content'>

                            <p className='ToggleText'>Yearly</p>
                            <div className="toggle-container" onClick={onToggle} style={containerStyle}>
                                <div className={`circle ${isToggled === 'yearly' ? "" : "toggled"}`} style={circleStyle}></div>
                            </div>
                            <p className='ToggleText'>Monthly</p>
                        </div>
                    </div>

                </Row>
                <Row className='gradiantStyle card-space'>
                    {pricingPlans.map((plan) => (
                        <Col key={plan.name} md={4} className="mb-4 plan_name" >
                            <Card className="h-100 CardsContainerStyle">
                                <Card.Header className="bg-white border-0">
                                    <Card.Title as="h4" className="text-center CardTitleStyle">{plan.name}</Card.Title>
                                </Card.Header>
                                <Card.Body className=''>
                                    <Card.Text as="div">
                                        <ul className="list-unstyled">
                                            <li className="list-item">
                                                <i className="bi bi-check-circle-fill text-success"></i>
                                                <div style={{ width: "5%" }}>
                                                    <span className='CheckIcon'><img src={correctIcon} alt='correctIcon' /></span>
                                                </div>
                                                <div style={{ width: "95%", fontSize: "12px", fontWeight: "400" }}>
                                                    perferendis alias expedita, quidem animighhjh magni obcaecati remquidem animi animi magni  remquidem.
                                                </div>
                                            </li>
                                            <li className="list-item">
                                                <i className="bi bi-x-circle-fill text-danger"></i>
                                                <div>
                                                    <span className='CheckIcon'><img src={correctIcon} alt='correctIcon' /></span>
                                                </div>
                                                odio quam et maiores cumque, illum alias jhjhj hjhjhj hjhjjj exercitationem magni oditquidem animi magni.
                                            </li>
                                            <li className="list-item">
                                                <i className="bi bi-x-circle-fill text-danger"></i>
                                                <div>
                                                    <span className='WrongIcon'><img src={wrongIcon} alt='wrongIcon' /></span>
                                                </div>
                                                quasi odio quam et maiores cumque,hggh illum alias exercitationem magni  animi magni   animi animi magn.
                                            </li>
                                            <li className="list-item">
                                                <i className="bi bi-x-circle-fill text-danger"></i>
                                                <div>
                                                    <span className='WrongIcon'><img src={wrongIcon} alt='wrongIcon' /></span>
                                                </div>
                                                quasi odio quam et maiores cumque, illum alias  mag oditquidem animi magni  remquidem animi anijhjhjhj.
                                            </li>
                                            <li className="list-item">
                                                <i className="bi bi-x-circle-fill text-danger"></i>
                                                <div className='CheckIcon'>
                                                    <span ><img src={correctIcon} alt='correctIcon' /></span>
                                                </div>
                                                odio quam ghghjhjhg et maiores cumque, illum alias exercitationem magni oditquidem animi animi animi.
                                            </li>
                                            <li className="list-item">
                                                <i className="bi bi-x-circle-fill text-danger"></i>
                                                <div>
                                                    <span className='WrongIcon'><img src={wrongIcon} alt='WrongIcon' /></span>
                                                </div>
                                                odio quam et ghgh jhghj maiores cumque, illum alias exercitationem magni oditquidem animi magnianimi.
                                            </li>
                                        </ul>
                                    </Card.Text>
                                    <Card.Footer className="bg-white border-0 text-center " style={{ backgroundColor: "transparent" }}>
                                        <button className='BuyCurrencyStyle' size="lg">
                                            Buy for ${getPrice(plan)}
                                        </button>
                                    </Card.Footer>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

export default Pricing;


//Bhagwan- Pricing page end //

import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import DashboardInviteClient from '../dashboardpage/Dasbord_bokeez/DashboardInviteClient';
import Dashboards from '../dashboardpage/dashboardSidebar/Dashboards';
import Protected from '../components/ProtectedLayout/protectedLayout';
import PageNotFound from '../components/pageNotFound/PageNotFound';


const BuyPlan = lazy(() => import('../dashboardpage/BuyPlan/BuyPlan'))
const DashbordData = lazy(() => import('../dashboardpage/customers/DashbordData'))
const InviteAccountant = lazy(() => import('../dashboardpage/dashboard/InviteAccountant'));
const AddNewUser = lazy(() => import('../dashboardpage/dashboard/AddNewUser'));
const AccountSetup = lazy(() => import('../dashboardpage/dashboard/AccountSetup'));
const Archive = lazy(() => import('../dashboardpage/costs/Archive'));
const Processing = lazy(() => import('../dashboardpage/costs/Processing'))
const Ready = lazy(() => import('../dashboardpage/costs/Ready'));
const Review = lazy(() => import('../dashboardpage/costs/Review'));
const RevenueProcessing = lazy(() => import('../dashboardpage/revenue/RevenueProcessing'));
const RevenueArchive = lazy(() => import('../dashboardpage/revenue/RevenueArchive'));
const RevenueReady = lazy(() => import('../dashboardpage/revenue/RevenueReady'));
const RevenueReview = lazy(() => import('../dashboardpage/revenue/RevenueReview'));
const Suppliers = lazy(() => import('../dashboardpage/suppliers/Suppliers'));
const Customers = lazy(() => import('../dashboardpage/customers/Customers'));
const Dashboard_Bookeez = lazy(() => import('../dashboardpage/Dasbord_bokeez/Dashbord'));
const Categories = lazy(() => import('../dashboardpage/moreList/Categories'));
const Tax = lazy(() => import('../dashboardpage/moreList/Tax'));
const Project = lazy(() => import('../dashboardpage/moreList/Project'));
const SystemSetting = lazy(() => import('../dashboardpage/moreList/SystemSetting'));
const Notification = lazy(() => import('../dashboardpage/moreList/Notification'));
const Teams = lazy(() => import('../dashboardpage/moreList/Team'));
const Subscription = lazy(() => import('../dashboardpage/moreList/Subscription'));
const Accountant = lazy(() => import('../dashboardpage/moreList/Accountant'));
const SubmissionHistory = lazy(() => import('../dashboardpage/moreList/SubmissionHistory'));
const MyDetails = lazy(() => import('../dashboardpage/moreList/MyDetails'));
const ManagePaymentSubmission = lazy(() => import('../dashboardpage/moreList/ManagePaymentSubmission'));
const ProfilePage = lazy(() => import('../dashboardpage/dashboard/Profile'));

const FileReadyPopup = lazy(() => import('../dashboardpage/costs/FileReadyPopup'));
const FileReviewPopup = lazy(() => import('../dashboardpage/costs/FileReviewPopup'));
const FileArchivePopup = lazy(() => import('../dashboardpage/costs/FileArchivePopup'));


const FileRevenueReadyPopup = lazy(() => import('../dashboardpage/revenue/FileRevenueReadyPopup'));
const FileRevenueReviewPopup = lazy(() => import('../dashboardpage/revenue/FileRevenueReviewPopup'));
const FileRevenueArchive = lazy(() => import('../dashboardpage/revenue/FileRevenueArchive'));


export const Pathurls = [

  { path: '/dashboard', component: Dashboard_Bookeez, title: 'Dashboard_Bookeez' },
  { path: '/dashboard-client', component: DashbordData, title: 'DashbordData' },

  { path: '/dashboard/addnewuser', component: AddNewUser, title: 'AddNewUser' },
  { path: '/dashboard/accountsetup', component: AccountSetup, title: 'AccountSetup' },
  { path: '/costs/archive', component: Archive, title: 'Archive' },
  { path: '/costs/processing', component: Processing, title: 'Processing' },
  { path: '/costs/ready', component: Ready, title: 'Ready' },
  { path: '/costs/review', component: Review, title: 'Review' },
  { path: '/revenue/processing', component: RevenueProcessing, title: 'RevenueProcessing' },
  { path: '/revenue/archive', component: RevenueArchive, title: ' RevenueArchive' },
  { path: '/revenue/ready', component: RevenueReady, title: 'RevenueReady' },
  { path: '/revenue/review', component: RevenueReview, title: 'RevenueReview' },
  { path: '/suppliers', component: Suppliers, title: 'Suppliers' },
  { path: '/customers', component: Customers, title: 'Customers' },
  { path: '/more-lists/categories', component: Categories, title: ' Categories' },
  { path: '/more-lists/tax', component: Tax, title: 'Tax' },
  { path: '/more-lists/project', component: Project, title: 'Project' },
  { path: '/more-lists/system-setting', component: SystemSetting, title: 'SystemSetting' },
  { path: '/more-lists/notification', component: Notification, title: 'Notification' },
  { path: '/manage-teams', component: Teams, title: 'teams' },
  { path: '/more-lists/subscription', component: Subscription, title: 'Subscription' },
  { path: '/more-lists/accountant', component: Accountant, title: 'Accountant' },
  { path: '/more-lists/submission-history', component: SubmissionHistory, title: 'SubmissionHistory' },
  { path: '/more-lists/my-details', component: MyDetails, title: 'MyDetails' },
  { path: '/more-lists/manage-payment-submission', component: ManagePaymentSubmission, title: ' ManagePaymentSubmission' },
  { path: '/dashboard/profilePage', component: ProfilePage, title: 'ProfilePage' },


  { path: '/costs/ready/file-ready/:id', component: FileRevenueReviewPopup, title: 'FileReadyPopup' },
  { path: '/costs/review/file-review/:id', component: FileRevenueReviewPopup, title: 'FileReviewPopup' },
  { path: '/costs/archive/file-archive/:id', component: FileRevenueReviewPopup, title: 'FileArchivePopup' },


  { path: '/revenue/ready/file-revenue-ready/:id', component: FileRevenueReviewPopup, title: 'FileRevenueReadyPopup' },
  { path: '/revenue/review/file-revenue-review/:id', component: FileRevenueReviewPopup, title: 'FileRevenueReviewPopup' },
  { path: '/revenue/archive/file-revenue-archive/:id', component: FileRevenueReviewPopup, title: ' FileRevenueArchive' },

];

export const adminPathURL = [
  { path: '/buyplan', component: BuyPlan, title: 'BuyPlan' },
  { path: '/submission-history', component: Subscription, title: 'SubmissionHistory' },
  { path: '/buyplan', component: BuyPlan, title: 'BuyPlan' },
  { path: '/accountant-dashboard', component: DashbordData, title: 'DashbordData' },
  { path: '/dashboard/inviteaccountant', component: DashboardInviteClient, title: 'DashboardInviteClient' },
  { path: '/inviteaccountant', component: InviteAccountant, title: 'InviteAccountant' },
  { path: '/dashboard/accountant/profilePage', component: ProfilePage, title: 'ProfilePage' },
]

export const ProtectedROute = ({ component }) => {
  const user_auth_id = localStorage.getItem('access');
  if (user_auth_id) {
    return component;
  } else {
    return <Navigate to="/login" />

  }
}

const DashboardRoutes = () => {

  return (

    <Routes>
      {Pathurls.map((route) => (
        <Route
          key={route.title}
          path={route.path}
          element={
            <Dashboards>
              <Protected>
                <route.component />
              </Protected>
            </Dashboards>
          }
        />
      ))}
    </Routes>
  );
};

export default DashboardRoutes;



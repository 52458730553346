import React from 'react';
import './loginsignupheader.scss';
import BookeezLogo from "../../assets/images/BookeezLogo.png"
import { Link } from 'react-router-dom';

const LoginSignupHeader = () => {
    return (
        <div className="Loginheader">
            <Link to='/' className='logo2 ms-3'>
                <img src={BookeezLogo} alt='BookeezLogo' />
            </Link>
        </div>
    );
}

export default LoginSignupHeader;


import {
     ActionTypes } from "./actionTypes";


const initialState= {
    products:[],
    country:[],
    verifyEmail:[],
    signUp:[],
    login:[],
    forgotPassword:[],
    createPassword:[],
    updatePassword:[],
}

const initialApiState= {
    fetchStatus:false,
    fetchCostStatus:false
}


export const userLoginReducer = (state={},action)=>{
    let {type,
    } = action
    switch(type){
        case ActionTypes.LOGIN_SUCCESS:
            return {
                ...state,login:action.data
            };
            default:return state
    }
}

    export const forgotPasswordReducer = (state={},action)=>{
        let{type,payload}=action
        switch(type){
            case ActionTypes.FORGOT_PASSWORD_SUCCESS:
                return {
                    ...state,forgotPassword:payload
                };
                default:return state
        }
    }


    export const createNewPasswordReducer = (state={},action)=>{
        let{type,
        }=action
        switch(type){
            case ActionTypes.CREATE_NEW_PASSWORD_SUCCESS:
                return {
                    ...state,createPassword:action.payload
                };
                default:return state
        }
    }

    export const updateClientPasswordReducer = (state={},action)=>{
        let{type}=action
        switch(type){
            case ActionTypes.UPDATE_PASSWORD_SUCCESS:
                return {
                    ...state,updatePassword:action.payload
                };
                default:return state
        }
    }





export const getCountriesReducer = (state={},action)=>{
    let {type}=action
    switch(type){
        case ActionTypes.GET_COUNTRY_Success:
            return{
                ...state,country:action.countryData?.data.message
            };
            default:
                return state
    }
}

export const postSignUpReducer = (state={},action)=>{
    let {type,payload} = action
    switch(type){
        case ActionTypes.SIGN_UP_SUCCESS:
            return{
                ...state,signUp:action?.data?.message
            };
        case ActionTypes.SIGN_UP_FAILURE:
            return{
                    ...state,signUp:payload
                };
            default:
                return state
    }
}


    export const postVerifyEmailReducer = (state={},action)=>{
        let {type,payload} = action
        switch(type){
            case ActionTypes.VERIFY_EMAIL_SUCCESS:
                return{
                    ...state,verifyEmail:action.message
                };
                default:
                    return state
        }
    }


    export const logoutReducer = (state={},action)=>{
        const {type,payload}=action
        switch(type){
            case ActionTypes.LOGOUT:
                return {
                    ...state,logout:payload
                }
            default:
                return state
        }
    }

    export const fetchApiReducer = (state=initialApiState,action)=>{
        const {type,payload}=action
        switch(type){
            case ActionTypes.FETCHAPI:
                return {
                    ...state,fetchStatus:payload
                }
            default:
                return state
        }
    }

    export const fetchCostsApiReducer = (state=initialApiState,action)=>{
        const {type,payload}=action
        switch(type){
            case ActionTypes.FETCOSTSCHAPI:
                return {
                    ...state,fetchCostStatus:payload
                }
            default:
                return state
        }
    }





import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    forgotPasswordRequest,
    forgotPasswordSuccess,
    forgotPasswordFailure,
} from "../../redux/actions";

import { BASE_URL } from "../../constant/common";
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import _FetchApi from "../../services/fetchservice";


const ResetPasswordVerifyMail = () => {
    const [showMessage, setShowMessage] = useState()
    const [searchParams] = useSearchParams();
    const loc = useLocation()
    const navigate = useNavigate()

    const token = searchParams.get('code');
    const requestValue = searchParams.get('request');
    const dispatch = useDispatch();
    const { loading, error } = useSelector((state) => state.verifyEmail);
    useEffect(() => {
        if (!token) {
            dispatch(forgotPasswordRequest({ token }));
            ResetPasswordverifyEmail();

            setShowMessage("Invalid or missing token.", "error");
            return;
        } else {
            const getMessage = localStorage.getItem('message')
            const messageArray = JSON.parse(getMessage)
            delete messageArray?.user_data?.is_client
            navigate('/createnewpassword', { state: { token, loc, requestValue } })
        }


        async function ResetPasswordverifyEmail() {
            try {
                const response = await _FetchApi("POST", `${BASE_URL}code=${token}`);
                const data = await response.json();

                if (response.status === 200) {
                    dispatch(forgotPasswordSuccess(data));

                } else {
                    dispatch(forgotPasswordFailure(data.message));
                }
            } catch (err) {
                dispatch(forgotPasswordFailure("An error occurred while verifying your email."));
            }
        }

    }, [token, dispatch]);

    if (loading) {
        return <h5>Loading...</h5>;
    }
    if (error) {
        return <div>Error: {error}</div>;
    }
    return <div>Reset Password Verification completed successfully.</div>;
}

export default ResetPasswordVerifyMail;


import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./assets/style/main.scss";
import Homepage from "./pages/homepage/Homepage";
import About from "./pages/about/About";
// import Blogs from "./pages/blogs/Blogs";
import Pricing from "./pages/pricing/Pricing";
import Support from "./pages/supports/Support";
import Login from "./components/login/Login";
import Signup from "./components/signup/Signup";
import DashboardRoutes, { Pathurls, adminPathURL } from "./routes/DashboardRoutes";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import VerifyMail from "./components/verifyEmail/VerifyMail";
import CreateNewPassword from "./components/createNewPassword/CreateNewPassword";
import ResetPasswordVerifyMail from "./components/resetPasswordVerifyMail/ResetPasswordVerifyMail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageNotFound from "./components/pageNotFound/PageNotFound";
import Dashboards from "./dashboardpage/dashboardSidebar/Dashboards";
import Protected from "./components/ProtectedLayout/protectedLayout";
import AdminDashboard from "./dashboardpage/dashboardSidebar/AdminDashboard";
import Blogs from "./pages/blogs/Blogs";
import Accountantprotected from "./components/ProtectedLayout/AccountantprotectedLayout";

function App() {
  const [data,setData]= useState(null)
  const getProfileData= (data)=>{
    console.log(data)
    setData(data)
  }
  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
         <Route path="/blogs" element={<Blogs />} /> 
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/support" element={<Support />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/auth/verify_email" element={<VerifyMail />} />
        <Route path="/createnewpassword" element={<CreateNewPassword />} />
        <Route
          path="/auth/set_password"
          element={<ResetPasswordVerifyMail />}
        />
        <Route
          path="/auth/update_password"
          element={<ResetPasswordVerifyMail />}
        />
        {Pathurls.map((route) => (
          <Route
            key={route.title}
            path={route.path}
            element={
              <Dashboards getProfileData={getProfileData}>
                <Protected>
                  <route.component />
                </Protected>
              </Dashboards>
            }
          />
        ))}

        {adminPathURL.map((route) => (
          <Route
            key={route.title}
            path={route.path}
            element={
              <AdminDashboard>
                <Accountantprotected>
                  <route.component />
                </Accountantprotected>
              </AdminDashboard>
            }
          />
        ))}

        <Route path='*' element={<PageNotFound />} />
      </Routes>


    </div>
  );
}

export default App;




import React, { Suspense, useEffect } from 'react';
import Sidebar from '../../sidebar/Sidebar';
import DashboardHeader from '../../dashboardHeader/DashboardHeader';
import "./styleDashboard/dashboard.scss"
import ClientSmallHeader from '../../dashboardHeader/ClientSmallHeader';
import { useNavigate } from 'react-router';

const Dashboards = ({ children }) => {
  const adminClientId = localStorage.getItem('admin_client_id') !== null;
  const ClientId = localStorage.getItem('is_client') === 'true';
  const navigate = useNavigate();
  const ClientType = JSON.parse(localStorage.getItem('user_type'))?.user_type;

  const path = window.location.pathname;
  let IsProfileUrl = path == '/dashboard/profilePage'


  useEffect(() => {
    if (!adminClientId && !ClientId && ClientType !== 3) {
      navigate('/accountant-dashboard');
    }
  }, [adminClientId, ClientId, navigate]);

  return (
    <>
      <DashboardHeader />
      <div className="d-flex dashboard" >
        <Sidebar />
        <div className="flex-grow-1 main_box" >
          <Suspense
          >
            <div className="middle-sec">
              <ClientSmallHeader show={adminClientId && !IsProfileUrl} />
              {children}
            </div>
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default Dashboards;

import React, { useEffect } from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { MdModeComment, MdOutlineScreenShare } from 'react-icons/md';
import blogs from "../../assets/images/blogs.png";
import blogcardone from '../../assets/images/blogcardone.png';
import blogcardtwo from '../../assets/images/blogcardtwo.png';
import blogcardhree from '../../assets/images/blogcardthree.png';
import blogcardfour from '../../assets/images/blogcardfour.png';
import './blogs.scss';
import { useLocation } from 'react-router-dom';

const blogData = [
    {
        imgSrc: blogcardone,
        heading: 'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum',
        para: 'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet...',
        date: '23 November 2023',
        commentCount: 23,
        shareCount: 0
    },
    {
        imgSrc: blogcardtwo,
        heading: 'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum',
        para: 'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet...',
        date: '23 November 2023',
        commentCount: 23,
        shareCount: 0
    },
    {
        imgSrc: blogcardhree,
        heading: 'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum',
        para: 'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet...',
        date: '23 November 2023',
        commentCount: 23,
        shareCount: 0
    },
    {
        imgSrc: blogcardfour,
        heading: 'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum',
        para: 'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet...',
        date: '23 November 2023',
        commentCount: 23,
        shareCount: 0
    }
];

const Blogs = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>

            <Header />

            <div className='blog_Container'>
                <div>
                    <img src={blogs} alt="" style={{ width: "100%" }} />
                    <div className="text_overlay">
                        <h3>Bookeez Blogs</h3>
                        <p>Stay tuned for more updates as we continue to monitor and report on the latest trends in bookkeeping.</p>
                    </div>
                </div>
            </div>
            <div className='cards_container'>
                {blogData.map((blog, index) => (
                    <div key={index} className='card_border'>
                        <div className='content'>
                            <img src={blog.imgSrc} alt="" className='cardImg' />
                            <p className='lorem_heading'>{blog.heading}</p>
                            <p className='lorem_para'>{blog.para}</p>
                        </div>
                        <hr />
                        <div className="card-dates">
                            <p>{blog.date}</p>
                            <div className='iconscards'>
                                <p className='card-comment'><MdModeComment className='card-icons' />{blog.commentCount}</p>
                                <p className='card-comment'><MdOutlineScreenShare className='card-icons' />{blog.shareCount}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Footer />
        </>
    );
}

export default Blogs;

import React, { useEffect } from 'react';
import user from "./../../assets/images/Rectangle 388.png"
import "./../../pages/supports/support.scss"
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';

const Support = () => {

    const { pathname } = useLocation();



    const validationSchema = Yup.object({
        firstname: Yup.string().required('required*'),
        lastname: Yup.string().required('required*'),
        email: Yup.string().email('Invalid email address').required('required*'),
        // mobile: Yup.string().matches(/^[0-9]+$/, '*invalid mobile number').required('*required'),
        mobile: Yup
            .string()
            .matches(/^[0-9]{10,}$/, 'Invalid mobile number')
            .required('*required'),
        description: Yup.string().required('required*'),
    });

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            email: '',
            mobile: '',
            description: '',
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            resetForm()
        },
    });

    const isFormValid = formik.isValid && formik.dirty;
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <>
            <Header />
            <div className='main_section'>
                <div className='support-page-grid'>
                    <h1 className="card-heading">Accurate financials. Total peace of mind.</h1>
                    <div className='card-para support-para' style={{ width: "95%" }}> <p>Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet tincidunt eu a.Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet tincidunt eu a.</p>
                        <p>Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet tincidunt eu a.</p></div>
                    <img src={user} alt="" />

                    <div className="support-form ">
                        <h2 className="conversation-head pb-lg-3">Let’s start the conversation!</h2>

                        <form onSubmit={formik.handleSubmit} style={{ width: "95%" }}>
                            <div className="row pb-lg-2">
                                <div className="form-group col-md-6">
                                    <label for="firstname" className='converstion-label'>First Name</label><span className="mandetory-fileds">*</span>
                                    <input type="text"
                                        className="form-control rect-box"
                                        id="firstname"
                                        value={formik.values.firstname}
                                        {...formik.getFieldProps('firstname')}
                                    />
                                    {formik.touched.firstname && formik.errors.firstname ? (
                                        <div className="error">{formik.errors.firstname}</div>
                                    ) : null}
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="lastname" className='converstion-label' >Last Name</label><span className="mandetory-fileds">*</span>
                                    <input type="text" className="form-control rect-box" id="lastname"
                                        value={formik.values.lastname}
                                        {...formik.getFieldProps('lastname')}
                                    />
                                    {formik.touched.lastname && formik.errors.lastname ? (
                                        <div className="error">{formik.errors.lastname}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row pb-md-2 pt-lg-3">
                                <div className="form-group col-md-6">
                                    <label for="email" className='converstion-label' >Email</label><span className="mandetory-fileds">*</span>
                                    <input type="email" className="form-control rect-box" id="email"

                                        {...formik.getFieldProps('email')}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="error">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="mobile" className='converstion-label'>Phone Number</label><span className="mandetory-fileds">*</span>
                                    <input type="text" className="form-control rect-box" id="mobile"
                                        onChange={(e) => {
                                            const numericValue = e.target.value.replace(/\D/g, '');
                                            formik.handleChange({
                                                target: {
                                                    name: "mobile",
                                                    value: numericValue,
                                                },
                                            });
                                        }}
                                        value={formik.values.mobile}
                                        // {...formik.getFieldProps('mobile')}
                                        maxLength={10}
                                    // minLength={10}
                                    />
                                    {formik.touched.mobile && formik.errors.mobile ? (
                                        <div className="error">{formik.errors.mobile}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group pt-lg-3 pb-lg-3">
                                <label for="description" className='converstion-label'>Description</label><span className="mandetory-fileds">*</span>
                                <textarea
                                    className="form-control rect-box1"
                                    id="description"
                                    {...formik.getFieldProps('description')}
                                ></textarea>
                                {formik.touched.description && formik.errors.description ? (
                                    <div className="error">{formik.errors.description}</div>
                                ) : null}
                            </div>
                        </form>
                        <button type="submit"
                            className={`mt-4 suport-btn ${isFormValid ? 'active' : 'disabled'}`}
                            onClick={formik.handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Support;


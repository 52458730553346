export const ActionTypes={
    LOGIN_REQUEST:"LOGIN_REQUEST",
    LOGIN_SUCCESS:"LOGIN_SUCCESS",
    LOGIN_FAILURE:"LOGIN_FAILURE",
    GET_COUNTRY_Request:"GET_COUNTRIES_Request",
    GET_COUNTRY_Success:"GET_COUNTRIES_Success",
    GET_COUNTRY_Failure:"GET_COUNTRIES_Failure",
    VERIFY_EMAIL_REQUEST:"VERIFY_EMAIL_REQUEST",
    VERIFY_EMAIL_SUCCESS:"VERIFY_EMAIL_SUCCESS",
    VERIFY_EMAIL_FAILURE:"VERIFY_EMAIL_FAILURE",
    SIGN_UP_REQUEST:"SIGN_UP_REQUEST",
    SIGN_UP_SUCCESS:"SIGN_UP_SUCCESS",
    SIGN_UP_FAILURE:"SIGN_UP_FAILURE",
    FORGOT_PASSWORD_REQUEST:"FORGOT_PASSWORD_REQUEST",
    FORGOT_PASSWORD_SUCCESS:"FORGOT_PASSWORD_SUCCESS",
    FORGOT_PASSWORD_FAILURE:"FORGOT_PASSWORD_FAILURE",
    CREATE_NEW_PASSWORD_REQUEST:"CREATE_NEW_PASSWORD_REQUEST",
    CREATE_NEW_PASSWORD_SUCCESS:"CREATE_NEW_PASSWORD_SUCCESS",
    CREATE_NEW_PASSWORD_FAILURE:"CREATE_NEW_PASSWORD_FAILURE",
    LOGOUT:"LOGOUT",
    FETCHAPI:"FETCHAPI",
    FETCOSTSCHAPI:'FETCOSTSCHAPI',
    UPDATE_PASSWORD_REQUEST:"UPDATE_PASSWORD_REQUEST",
    UPDATE_PASSWORD_SUCCESS:"UPDATE_PASSWORD_SUCCESS",
    UPDATE_PASSWORD_FAILURE:"UPDATE_PASSWORD_FAILURE",
}




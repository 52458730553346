import {combineReducers} from 'redux'

import { createNewPasswordReducer, fetchApiReducer, fetchCostsApiReducer, forgotPasswordReducer, getCountriesReducer, logoutReducer, postSignUpReducer, postVerifyEmailReducer, updateClientPasswordReducer, userLoginReducer,
    } from "./reducers" 
const reducers=combineReducers({
    country:getCountriesReducer,
    verifyEmail:postVerifyEmailReducer,
    signUp:postSignUpReducer,
    login:userLoginReducer,
    forgotPassword:forgotPasswordReducer,
    createPassword:createNewPasswordReducer,
    logout:logoutReducer,
    fetch:fetchApiReducer,
    fetchCosts:fetchCostsApiReducer,
    updateClientPasswordReducer:updateClientPasswordReducer,
})

export default reducers



import React from 'react';
import "./footer.scss";
import {  NavLink } from 'react-router-dom';
import Vector from "../../assets/images/Vector.png"
import TwitterX from "../../assets/images/TwitterX.png"
import Instagram from "../../assets/images/Instagram.png"
import YouTube from "../../assets/images/YouTube.png";
import bookeezlogotransparent from "../../assets/images/bookeezlogotransparent.png"
 
const Footer = () => {
    return (
        <div>
            <div className="footer">
                
                <img src={bookeezlogotransparent} alt=""   />
                    
                <div className='footer_list'>
                <ul className='mb-0'>
                    <li>
                        <NavLink activeClassName="active">
                            Terms and conditions
                        </NavLink>
                    </li>
                    <li>
                        <NavLink  activeClassName="active">
                            Privacy policy
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/support" activeClassName="active">
                            Support
                        </NavLink>
                    </li>  <li>
                        <NavLink to="/about" activeClassName="active">
                            About us
                        </NavLink>
                    </li>
                    <li>
                        <NavLink  activeClassName="active">
                            FAQ’s
                        </NavLink>
                    </li>
                </ul>
                </div>
                
                <div className="user_actions_footer">
                    <img src={Vector} alt="vector" className='social_links_footer' />
                    <img src={TwitterX} alt="twitter" className='social_links_footer' />
                    <img src={Instagram} alt="instagram" className='social_links_footer' />
                    <img src={YouTube} alt="youtube" className='social_links_footer' />
                </div>
            </div>
        </div>
    );
}
 
export default Footer;


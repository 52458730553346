// Header.js

import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Login from '../../assets/images/Login.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './header.scss';
import BookeezLogo from "../../assets/images/BookeezLogo.png"

const Header = () => {
    return (
        <div className="header">
            <Link to='/' className='logo text-center text-sm-start d-flex'>
                <img src={BookeezLogo} alt="" style={{ marginTop: "8px" }} />
            </Link>
            <Navbar expand="lg" className="NavbarContainerStyle">

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="">
                        <div className='headerlist'>
                            <ul>
                                <li >
                                    <NavLink to="/" activeClassName="active">
                                        Home
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/about" activeClassName="active">
                                        About
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink to="/blogs" activeClassName="active">
                                        Blog
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/pricing" activeClassName="active">
                                        Pricing
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/support" activeClassName="active">
                                        Support
                                    </NavLink>
                                </li>
                            </ul>

                        </div>

                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <div className="user_actions" style={{ gap: "10px" }}>
                <Link to='/login' className='no-text-decoration'>
                    <button>
                        Login <span><img src={Login} alt="Login" /></span>
                    </button>
                </Link>
                <Link to='/signup' className='no-text-decoration'>
                    <button>
                        Sign up <span><img src={Login} alt="Signup" /></span>
                    </button>
                </Link>
            </div>

        </div>
    );
}

export default Header;
import { MdLogout } from "react-icons/md";
import { useNavigate } from "react-router";
const ClientSmallHeader = ({ show }) => {
    const navigate = useNavigate();
    const adminClientId = localStorage.getItem('admin_client_name')

    const HandleClientLogout = () => {
        localStorage.removeItem('admin_client_id');
        localStorage.removeItem('admin_client_name');
        navigate('/accountant-dashboard');
    }
    if (show) {
        return (
            <div className='px-5 py-2 d-flex justify-content-end align-items-center gap-2'>
                <span className='fw-semibold text-capitalize' >{adminClientId}</span>
                <span role="button" onClick={HandleClientLogout}><MdLogout size={25} color="#328ED9" /></span>
            </div>
        )
    } else { return null }

}

export default ClientSmallHeader
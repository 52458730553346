

import React from 'react';
import { Navigate } from 'react-router-dom';

const Accountantprotected = ({ children }) => {
    const userType = JSON.parse(localStorage.getItem("user_type"));
    
    if (localStorage.getItem("access") && userType?.user_type === 1) {
        return children;
    } else {
        return <Navigate to={'/login'} replace />;
    }
};

export default Accountantprotected;
import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import { FaFileAlt } from 'react-icons/fa';
import './style/choosePopup.scss';
import Vectorpopup from '../../assets/images/Vectorpopup.png';
import { PostAddDocument } from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import '../moreList/style/tax.scss';
import { fetchCostsApi } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import AvatarEditor from 'react-avatar-editor';
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaCameraRotate } from "react-icons/fa6";

const ChooseFilePopup = ({ openchooseFile, closeChooseFile }) => {
    const [files, setfiles] = useState(null);
    const [fileCount, setFileCount] = useState(true);
    const [rotation, setRotation] = useState(0);
    const [btnDisable, setBtnDisable] = useState(false);

    const [isDragging, setIsDragging] = useState(false);
    const [image, setImage] = useState({
        img: null,
        secondImg: null
    });
    const [rotatedImage, setRotatedImage] = useState({
        rotatedImg: null,
        secondRotatedImg: null
    });
    const [angle, setAngle] = useState(0);

    const inputRef = useRef();
    const FileinputRef = useRef();
    const editorRef = useRef(null);
    const editorRef2 = useRef(null);

    const [errorMessage, setErrorMessage] = useState({
        type: '',
        file: '',
        secondFile: '',
    });
    const [formData, setFormData] = useState({
        file: null,
        secondFile: null,
        selectedOption: '',
    });

    const ApiStatus = useSelector((state) => state.fetchCosts.fetchCostStatus);
    const dispatch = useDispatch();
    const ResetFields = {
        file: '',
        selectedOption: '',
        secondFile: '',
    };

    const navigate = useNavigate();

    const handleFileChange = (e) => {
        handleImageUpload(e, 'first')
        const selectedFile = e.target.files ? e.target.files[0] : null;
        setFormData({ ...formData, file: selectedFile });
        formik.setFieldValue('file', selectedFile);
    };

    const handleSecondFileChange = (e) => {
        handleImageUpload(e, 'second')
        const selectedFile = e.target.files ? e.target.files[0] : null;
        setFormData({ ...formData, secondFile: selectedFile });
        formik.setFieldValue('secondFile', selectedFile);
    };

    const handleSelectChange = (e) => {
        setFormData({ ...formData, selectedOption: e.target.value });
        formik.setFieldValue('selectedOption', e.target.value);
    };

    const getValidationSchema = () => {
        return Yup.object().shape({
            selectedOption: Yup.string().required('Please select one option'),
            file: Yup.mixed().required('Please select a file'),
            secondFile: fileCount ? Yup.mixed().nullable() : Yup.mixed().required('Please select a file'),
        });
    };

    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const formik = useFormik({
        initialValues: {
            file: null,
            secondFile: null,
            selectedOption: '',
        },
        validationSchema: getValidationSchema(),
        onSubmit: (values) => {
            let user_id = localStorage.getItem('id');
            const clientId = localStorage.getItem('admin_client_id');
            // const canvas = editorRef.current.getImage();
            // const canvas2 = editorRef2.current ? editorRef2.current.getImage() : null; // Adjusted for potential null
            setBtnDisable(true);
            if(formData?.secondFile){
                const secondRotatedFile = dataURLtoFile(rotatedImage?.secondRotatedImg, formData?.secondFile?.name);
            }
            const rotatedFile = dataURLtoFile(rotatedImage?.rotatedImg, formData?.file?.name);

            const data = new FormData();
            data.append('add_document', rotatedFile);
            if(formData?.secondFile){
                const secondRotatedFile = dataURLtoFile(rotatedImage?.secondRotatedImg, formData?.secondFile?.name);
                data.append('add_new_document', secondRotatedFile);
            }
            data.append('document_type', formData.selectedOption === 'Costs' ? 0 : 1);
            data.append('submission_method', '0');
            data.append('document_owner', clientId ? clientId : user_id);

            PostAddDocument(data, user_id)
                .then((res) => {
                    if (res.data.status === 'success') {
                        toast.success(res.data.message.message);
                        navigate(`/${formData.selectedOption === 'Costs' ? 'costs' : 'revenue'}/processing`);
                        setFormData({ ...ResetFields });
                        dispatch(fetchCostsApi(!ApiStatus));
                        closeChooseFile();
                    } else {
                        toast.error(res.data.message.message);
                    }
                    setBtnDisable(false);
                })
                .catch((err) => {
                    toast.error('invalid request');
                });
        },

    });

    const handleFileInputChange = () => {
        inputRef.current.click();
    };

    const handleSecondFileInputChange = () => {
        FileinputRef.current.click();
    };

    const handleFileCountToggleClick = () => {
        setFileCount(!fileCount);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            const selectedFile = files[0];
            setFormData({ ...formData, file: selectedFile }); // Correctly set the file in formData
            const reader = new FileReader();
            reader.onload = (event) => {
                setImage({ ...image, img: event.target.result });
                setRotatedImage({ ...rotatedImage, rotatedImg: event.target.result }); // Initialize rotated image
            };
            reader.readAsDataURL(selectedFile); // Use selectedFile here
            formik.setFieldValue('file', selectedFile);
        }
    };

    const handleSecondDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            const selectedFile = files[0];
            setFormData({ ...formData, secondFile: selectedFile }); // Correctly set the secondFile in formData
            const reader = new FileReader();
            reader.onload = (event) => {
                setImage({ ...image, secondImg: event.target.result });
                setRotatedImage({ ...rotatedImage, secondRotatedImg: event.target.result }); // Initialize rotated image
            };
            reader.readAsDataURL(selectedFile); // Use selectedFile here
            formik.setFieldValue('secondFile', selectedFile);
        }
    };


    const handleImageUpload = (e, images) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                if (images === 'first') {
                    setImage({ ...image, img: event.target.result });
                    setRotatedImage({ ...rotatedImage, rotatedImg: event.target.result }); // Initialize rotated image
                    setFormData({ ...formData, file: file });
                } else {
                    setImage({ ...image, secondImg: event.target.result });
                    setRotatedImage({ ...rotatedImage, secondRotatedImg: event.target.result }); // Initialize rotated image
                    setFormData({ ...formData, secondFile: file });
                }
            };
            reader.readAsDataURL(file); // Ensure the correct file is read
        }
    };

    const rotateImage = (data, images) => {
        if (images === 'first') {
            const canvas = editorRef.current;
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.src = image.img;

            img.onload = () => {
                const width = img.width;
                const height = img.height;

                // Calculate the new canvas size to fit the rotated image
                const radians = (angle * Math.PI) / 180;
                const sin = Math.abs(Math.sin(radians));
                const cos = Math.abs(Math.cos(radians));
                const newWidth = width * cos + height * sin;
                const newHeight = width * sin + height * cos;

                canvas.width = newWidth;
                canvas.height = newHeight;

                ctx.clearRect(0, 0, newWidth, newHeight);
                ctx.save();
                ctx.translate(newWidth / 2, newHeight / 2);
                ctx.rotate(radians);
                ctx.drawImage(img, -width / 2, -height / 2);
                ctx.restore();

                setAngle(angle + data);
                setRotatedImage({ ...rotatedImage, rotatedImg: canvas.toDataURL() }); // Initialize rotated image
                // setRotatedImage(canvas.toDataURL());
            };
        } else {
            const canvas = editorRef2.current;
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.src = image.secondImg;

            img.onload = () => {
                const width = img.width;
                const height = img.height;

                // Calculate the new canvas size to fit the rotated image
                const radians = (angle * Math.PI) / 180;
                const sin = Math.abs(Math.sin(radians));
                const cos = Math.abs(Math.cos(radians));
                const newWidth = width * cos + height * sin;
                const newHeight = width * sin + height * cos;

                canvas.width = newWidth;
                canvas.height = newHeight;

                ctx.clearRect(0, 0, newWidth, newHeight);
                ctx.save();
                ctx.translate(newWidth / 2, newHeight / 2);
                ctx.rotate(radians);
                ctx.drawImage(img, -width / 2, -height / 2);
                ctx.restore();

                setAngle(angle + data);
                setRotatedImage({ ...rotatedImage, secondRotatedImg: canvas.toDataURL() }); // Initialize rotated image
                // setRotatedImage(canvas.toDataURL());
            };
        }

    };

    useEffect(() => {
        if (rotation >= 360) {
            setRotation(0)
        }
    }, [rotation]);

    useEffect(() => {
        if (image.img) {
            rotateImage(0, 'first');
        } else if (image.secondImg) {
            rotateImage(0, 'second');
        }
    }, [angle, image]);

    return (
        <Modal show={openchooseFile} onHide={closeChooseFile} backdrop="static" keyboard={false} centered>
            <div className="upload_files_container position-relative">
                <div className="position-absolute top-0 end-0 p-1" onClick={closeChooseFile} style={{ cursor: "pointer" }}  ><IoMdCloseCircleOutline size={22} /></div>
                <div>
                    <div className="me-auto">
                        <h3 className="upload_heading">Upload</h3>
                    </div>
                    <div className="togle_button d-flex justify-content-center">
                        <div class="d-sm-flex justify-content-between align-items-center  togglebtn ">
                            <div class="d-sm-flex">
                                <div className="toggle-containers">
                                    <span className={`toggle-text ${fileCount ? 'active' : ''}`}>Single</span>
                                    <div className="check">
                                        <input id="check1" type="checkbox" onClick={handleFileCountToggleClick} />
                                        <label for="check1"></label>
                                    </div>
                                    <span className={`toggle-text ${!fileCount ? 'active' : ''}`}>Multiple</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div>
                        <div className="d-flex justify-content-center radioBtn_content mt-4">
                            <div className="d-flex content_upload">
                                <input
                                    type="radio"
                                    value="Costs"
                                    style={{ width: '12px', cursor: 'pointer' }}
                                    checked={formData.selectedOption === 'Costs'}
                                    onChange={handleSelectChange}

                                />

                                <h3 className="mt-2">Costs</h3>
                            </div>
                            <div className="d-flex justify-content-center content_upload">
                                <input
                                    type="radio"
                                    value="Revenue"
                                    className="custom-radio"
                                    style={{ width: '12px', cursor: 'pointer' }}
                                    checked={formData.selectedOption === 'Revenue'}
                                    onChange={handleSelectChange}
                                />
                                <h3 className="mt-2">Revenue</h3>
                            </div>
                        </div>
                        {formik.errors.selectedOption && formik.touched.selectedOption && (
                            <div className="text-danger text-center">{formik.errors.selectedOption}</div>
                        )}
                    </div>
                    <div className={`file-input-container ${isDragging ? ' dragging ' : ''}doted_border d-flex flex-column align-items-center justify-content-center`} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>

                        <div className='d-flex flex-column align-items-center justify-content-center'>
                            <img src={Vectorpopup} alt="" className="vector_img" />

                            <input
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                hidden
                                accept="image/png, image/jpeg, image/pdf"
                                ref={inputRef}
                            />
                            <button className="transparent_btn" onClick={handleFileInputChange}>
                                <h3 className="drag-content">
                                    Drag & drop files or <span>Browse</span>
                                </h3>
                            </button>
                        </div>
                        {formData.file && image.img ? (
                            <div className="avatar-ed itor-container">
                                <div className='mb-3'>
                                    <canvas ref={editorRef} style={{ display: 'none' }} />
                                    <img src={rotatedImage.rotatedImg || image.img} alt="Uploaded" />
                                </div>
                                <div className="rotate-buttons"   >
                                    <button type='button' className='btn btn_primary' onClick={() => rotateImage(90, 'first')}><FaCameraRotate /></button>
                                </div>
                                {/* <div className="rotate-buttons" >
                                    <input
                                        type="range"
                                        min="0"
                                        max="2"
                                        step="0.1"
                                        value={zoom}
                                        onChange={(e) => setZoom(parseFloat(e.target.value))}
                                    />

                                </div>
                                <p className='button_title'>Zoom: {zoom}</p> */}
                            </div>
                        ) : (
                            <p>No file selected</p>
                        )}
                        {files && (
                            <>
                                {Array.from(files).map((file, idx) => (
                                    <div className="d-flex justify-content-cem" key={idx}>
                                        <FaFileAlt /> <p>{file.name}</p>
                                    </div>
                                ))}
                            </>
                        )}
                        <p className="supported-file mb-0">Supported formats: JPEG, PNG</p>
                        <p className="supported-file">Supported vertical image format</p>
                        {formik.errors.file && formik.touched.file && (
                            <div className="text-danger">{formik.errors.file}</div>
                        )}
                    </div>
                    {!fileCount ? (
                        <div className={`file-input-container ${isDragging ? ' dragging ' : ''}doted_border d-flex flex-column align-items-center justify-content-center`} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleSecondDrop}>

                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <img src={Vectorpopup} alt="" className="vector_img" />

                                <input
                                    type="file"
                                    multiple
                                    onChange={handleSecondFileChange}
                                    hidden
                                    accept="image/png, image/jpeg, image/pdf"
                                    ref={FileinputRef}
                                />
                                <button className="transparent_btn" onClick={handleSecondFileInputChange}>
                                    <h3 className="drag-content">
                                        Drag & drop files or <span>Browse</span>
                                    </h3>
                                </button>
                            </div>
                            {formData.secondFile && (
                                <div>
                                    <p className="py-3 px-3">Selected File: {formData.secondFile.name}</p>
                                </div>
                            )}
                            {formData.secondFile ? (
                                <div className="avatar-ed itor-container">
                                    {/* <AvatarEditor ref={editorRef2}
                                        width={300} height={370} border={0}
                                        image={URL.createObjectURL(formData.secondFile)}
                                        scale={secondzoom + 0.95} rotate={secondrotation}
                                    /> */}
                                    <div className='mb-3'>
                                        <canvas ref={editorRef2} style={{ display: 'none' }} />
                                        <img src={rotatedImage.secondRotatedImg || image.secondImg} alt="Uploaded" />
                                    </div>
                                    <div className="rotate-buttons"   >
                                        <button type='button' className='btn btn_primary' onClick={() => rotateImage(90, 'second')}><FaCameraRotate /></button>
                                    </div>
                                    {/* <div className="rotate-buttons" >
                                        <input
                                            type="range"
                                            min="0"
                                            max="2"
                                            step="0.1"
                                            value={secondzoom}
                                            onChange={(e) => setSecondZoom(parseFloat(e.target.value))}
                                        />

                                    </div>
                                    <p className='button_title'>Zoom: {secondzoom}</p> */}
                                </div>
                            ) : (
                                <p>No file selected</p>
                            )}
                            {files && (
                                <>
                                    {Array.from(files).map((file, idx) => (
                                        <div className="d-flex justify-content-cem" key={idx}>
                                            <FaFileAlt /> <p>{file.name}</p>
                                        </div>
                                    ))}
                                </>
                            )}
                            <p className="supported-file mb-0">Supported formats: JPEG, PNG</p>
                            <p className="supported-file">Supported vertical image format</p>

                            {formik.errors.secondFile && formik.touched.secondFile && (
                                <div className="text-danger">{formik.errors.secondFile}</div>
                            )}
                        </div>
                    ) : (
                        ''
                    )}

                    <div className="btn_center">
                        <button className={`upload_files ${formik.isValid ? 'active' : 'disabled'}`} type="submit" disabled={btnDisable}>
                            Upload
                            {btnDisable && (
                                <div class="spinner-border text-light ms-2" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ChooseFilePopup;
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./signup.scss";
import { FiEye, FiEyeOff } from "react-icons/fi";
import placeholder from "./../../assets/images/placeholder.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LoginSignupHeader from "../loginSignupHeader/LoginSignupHeader";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../redux/actionTypes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import _FetchApi from "../../services/fetchservice";
import { BASE_URL } from "../../constant/common";
import PasswordStrengthBar from 'react-password-strength-bar';
import { AllCountryDataFormatted } from "./AllCountryDataFormatted";
import { MdOutlineErrorOutline } from 'react-icons/md';
import { blockedDomains } from "./disbaleEmailList";


const Signup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [country_Code, setCountry_Code] = useState('');
    const [errorCoun, setErrorCoun] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [phone_code, setPhoneCode] = useState(null)

    const [Email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isEmailDisabled, setIsEmailDisabled] = useState(false);


    const handleEmailChange = (event) => {
        formik.handleChange(event);
        const enteredEmail = event.target.value;
        setEmail(enteredEmail);

        const emailDomain = enteredEmail.split('@')[1];


        const isBlockedDomain = blockedDomains.includes(emailDomain);

        if (isBlockedDomain) {
            setEmailError(<MdOutlineErrorOutline />);
            setIsEmailDisabled(true);
        } else {
            setEmailError('');
            setIsEmailDisabled(false);
        }
    };


    const getCountryData = useSelector((state) => state.country.country);

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required("*required"),
        last_name: Yup.string().required("*required"),
        organization: Yup.string().required("*required"),
        email: Yup.string().email("Invalid email address.").required("*required"),
        country: Yup.string().required("*required"),
        country_code: Yup.string().required("*required"),
        phone_number: Yup.string().matches(/^[0-9]\d{7}$/, '*invalid mobile number').required('*required'),
        password: Yup.string()
            .required("*required")
            .test(
                value => {
                    const hasCapitalLetter = /[A-Z]/.test(value);
                    const hasNumber = /\d/.test(value);
                    const hasSpecialCharacter = /[!@#$%^&*]/.test(value);
                    return hasCapitalLetter && hasNumber && hasSpecialCharacter;
                }
            ),
        confirm_password: Yup.string()
            .oneOf([Yup.ref("password")], "passwords must match.")
            .required("please confirm your password."),
    })


    const formik = useFormik({
        initialValues: {
            first_name: "", country_code: country_Code,
            last_name: "",
            organization: "",
            email: "",
            country: "",

            phone_number: "",
            password: "",
            confirm_password: "",
            newPasswordVisible: false,
            confirmPasswordVisible: false,
        },
        validationSchema: validationSchema,

        onSubmit: async (values, { resetForm }) => {
            try {
                let form = document.getElementsByClassName('form-row')[0]
                if (form) {
                    form.classList.remove('formMarginBottom')
                }

                const { first_name, last_name, organization, email, country, phone_number, password, confirm_password } = values;
                const newData = { first_name, last_name, organization, email, phone_number, password, confirm_password, country_id: parseInt(country, 10) };
                const response = await _FetchApi("POST", `${BASE_URL}sign_up/`, newData, {
                    "Content-Type": "application/json",
                });
                resetForm();
                formik.resetForm()
                formik.setFieldValue("country_code", country_code)
                let code = response?.data?.code;

                if (code === 400) {
                    toast.error(response?.data?.message);
                } else if (code === 200) {
                    formik.resetForm()
                    toast.success(response?.data?.message?.message)
                }
            } catch (error) {
                console.error("Error during form submission:", error);
            }
        },
    });


    useEffect(() => {
        if (formik.values.country) {
            const phoneCode = getCountryData.find(
                (item) => item.id === parseInt(formik.values.country)
            );
            setCountry_Code(phoneCode.country_code)
            formik.setFieldValue("country_code", phoneCode.country_code);
        }
    }, [formik.values.country]);


    let formikDAta = formik.values
    let { first_name, last_name, organization, email, country, phone_number, country_code, password, confirm_password } = formikDAta
    let body = { first_name, last_name, organization, email, country, phone_number, country_code, password, confirm_password }


    const isFormIncomplete = Object.values(body).some(
        (value) => {
            return value === ""
        }
    );


    useEffect(() => {
        const phoneNumberInput = document.getElementById('phoneNumberInput');
        const form = document.getElementsByClassName('form-row')[0];

        if (!formik.values.phone_number || !phoneNumberInput) {
            return;
        }

        const num = String(phone_code);
        setPhoneCode(num);

        const newCountry = formik.values.country_code.slice(1);
        const data = AllCountryDataFormatted.find(item => item.phone === newCountry);

        const phoneLength = String(formik.values.phone_number).length;
        const isPhoneNumberValid = data && data.phoneLength === phoneLength;

        setPhoneError(!isPhoneNumberValid);
        phoneNumberInput.classList.toggle('is-invalid', !isPhoneNumberValid);
        phoneNumberInput.disabled = isPhoneNumberValid;

        if (form) {
            form.classList.toggle('formMarginBottom', !isPhoneNumberValid);
        }

        const handleKeyDown = (event) => {
            if (!data) return;

            if (event.key === 'Backspace') {
                phoneNumberInput.disabled = false;
            }

            const currentLength = phoneNumberInput.value.length;
            if (currentLength > data.phoneLength) {
                phoneNumberInput.disabled = true;
            } else {
                phoneNumberInput.disabled = false;
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [phone_code, formik.values.phone_number, formik.values.country_code]);



    useEffect(() => {
        formik.setFieldValue("country", getCountryData?.[0]?.id);
        fetchGetCountryData();
    }, []);

    useEffect(() => {
        if (getCountryData?.length > 0 && formik.values.country) {
            const phoneCode = getCountryData.find(
                (item) => item.id === formik.values.country
            );
            if (phoneCode) {
                formik.setFieldValue("country_Code", phoneCode.country_Code);
                formik.setFieldValue("country_Id", phoneCode.id);
            }
        }
    }, [getCountryData, formik.values.country]);


    // /******/ passwordStrengthBar/***** */
    let abc = document.getElementsByClassName('passwordStrengthBar');

    if (abc.length > 0) {
        let passwordStrengthBar = abc[0];

        let pElement = passwordStrengthBar.querySelector('p');
        if (pElement) {
            pElement.remove();
        }

        let remainingBars = passwordStrengthBar.querySelectorAll('div[style*="background-color"]');
        remainingBars.forEach(bar => {
            bar.style.height = '5px';
            bar.style.color = 'black';
        });
    }


    const fetchGetCountryData = async () => {
        try {
            dispatch({ type: ActionTypes.GET_COUNTRY_Request });
        } catch (error) {
            dispatch({
                type: ActionTypes.GET_COUNTRY_Failure,
                payload: error.message,
            });
        }
    };



    const handleNewSubmit = (e) => {
        e.preventDefault()
        if (!formik.values.country_code) {
            setErrorCoun(true)
            let data = document.getElementsByClassName('country')[0];
            if (data) {
                data.classList.add('is-invalid');
            }
        }
        if (!formik.values?.phone_number) {
            const phoneNumberInput = document.getElementById('phoneNumberInput');
            phoneNumberInput.classList.add('is-invalid')
            setPhoneError(true)
        }

        const pt2 = document.getElementById('newPassword')
        if (pt2) {
            pt2.classList.remove('pt-4')
            pt2.classList.add('pt-2')
        }

        let form = document.getElementsByClassName('form-row')[0]
        if (form) {
            form.classList.add('formMarginBottom')
        }

        formik.handleSubmit()
    }


    return (
        <>
            <LoginSignupHeader />

            <div className="custom-container ">
                <div className="row justify-content-evenly signup g-0">
                    <div className="col-md-6 flexorder1">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-row">
                                <div className="col">
                                    <input
                                        type="text"
                                        className={`form-control mt-3 Input-field ${formik.touched.first_name && formik.errors.first_name ? "is-invalid" : ""}`}
                                        placeholder="First name*"
                                        name="first_name"
                                        value={formik.values.first_name}
                                        // onChange={formik.handleChange}
                                        onChange={(event) => {
                                            const alphaRegex = /^[A-Za-z]+$/;
                                            if (alphaRegex.test(event.target.value) || event.target.value === '') {
                                                formik.handleChange(event);
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.first_name && formik.errors.first_name ? (
                                        <div className="invalid-feedback">{formik.errors.first_name}</div>
                                    ) : null}

                                </div>

                                <div className="col">
                                    <input
                                        type="text"
                                        className={`form-control mt-3 Input-field ${formik.touched.last_name && formik.errors.last_name ? "is-invalid" : ""}`}
                                        placeholder="Last name*"
                                        name="last_name"
                                        value={formik.values.last_name}
                                        // onChange={formik.handleChange}
                                        onChange={(event) => {
                                            const alphaRegex = /^[A-Za-z]+$/;
                                            if (alphaRegex.test(event.target.value) || event.target.value === '') {
                                                formik.handleChange(event);
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.last_name && formik.errors.last_name ? (
                                        <div className="invalid-feedback">{formik.errors.last_name}</div>
                                    ) : null}
                                </div>

                                <div className="col">
                                    <input
                                        type="text"
                                        className={`form-control mt-3 Input-field ${formik.touched.organization && formik.errors.organization ? "is-invalid" : ""}`}
                                        placeholder="Organization*"
                                        name="organization"
                                        value={formik.values.organization}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.organization && formik.errors.organization ? (
                                        <div className="invalid-feedback">{formik.errors.organization}</div>
                                    ) : null}
                                </div>

                                <div className="col">
                                    <div className="input-icon-container" style={{ maxWidth: "400px" }}>
                                        <input
                                            name="email"
                                            type="email"
                                            className={`form-control mt-3 Input-field ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}
                                            placeholder="Work email*"
                                            value={formik.values.email}
                                            onChange={handleEmailChange}
                                            onBlur={formik.handleBlur}
                                            disabled={isEmailDisabled}
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                            <MdOutlineErrorOutline className="input-icon" />
                                        ) : null}
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className="invalid-feedback">{formik.errors.email}</div>
                                        ) : null}
                                    </div>
                                </div>

                                {console.log('formik.values.country', formik.values.country)}
                                <select
                                    name="country"
                                    className={`form-control mt-3 country ${!formik.values.country ? 'country_light' : 'country_dark'} ${formik.touched.country && formik.errors.country ? "is-invalid" : ""}`}
                                    value={formik.values.country}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value="" disabled selected>Country*</option>
                                    {getCountryData &&
                                        getCountryData.map((country, index) => (
                                            <option key={index} value={country.id}>
                                                {country.country_name}
                                            </option>
                                        ))}
                                </select>

                                {errorCoun ? (
                                    <div className="invalid-feedback">{formik.errors.country}</div>
                                ) : null}


                                <div className="col">
                                    <div className="form-row align-items-center input_phone">
                                        <div className="d-flex">
                                            {formik.values.country ?
                                                <PhoneInput disabled
                                                    name="country"
                                                    country={formik.values.country}
                                                    value={formik.values.country_code}
                                                    inputProps={{
                                                        className: `form-control mt-3 Input-code-field p-0 ps-2  ${formik.touched.country_code && formik.errors.country_code ? "is-invalid" : ""} ${formik.values.country_code ? "" : "text-start"}`,
                                                        placeholder: "Code*",
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                />
                                                :
                                                <>
                                                    <PhoneInput disabled name="country"
                                                        inputProps={{
                                                            className: `form-control mt-3 Input-code-field p-0 ps-2  ${formik.touched.country_code && formik.errors.country_code ? "is-invalid" : ""} text-start`,
                                                            placeholder: "Code*",
                                                        }}
                                                    />
                                                </>
                                            }

                                            <input
                                                type="text"
                                                name="phone_number"
                                                id="phoneNumberInput"
                                                autocomplete="off"
                                                value={formik.values.phone_number}
                                                className={`form-control mt-3  Input-number-field ${formik.touched.phone_number && formik.errors.phone_number ? "is-invalid" : ""}`}
                                                placeholder="Phone number*"
                                                onChange={(e) => {
                                                    const numericValue = e.target.value.replace(/\D/g, '');
                                                    formik.handleChange({
                                                        target: {
                                                            name: "phone_number",
                                                            value: numericValue,
                                                        },
                                                    });
                                                }}
                                                onBlur={formik.handleBlur}
                                                pattern="[0-8]*"
                                            />
                                        </div>

                                        {phoneError ? (
                                            <div className="invalid-feedback-Phone">*required</div>) : null}
                                    </div>
                                </div>
                            </div>

                            <div id="newPassword" className="form-row pt-2">
                                <div className="position-relative" style={{ maxWidth: "400px" }}>
                                    <input
                                        type={formik.values.newPasswordVisible ? "text" : "password"}
                                        name="password"
                                        id="new-password"
                                        placeholder="Create password*"
                                        className={`form-control mt-4 Input-field ${formik.touched.password && formik.errors.password ? "is-invalid" : ""}`}
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => formik.setFieldValue("newPasswordVisible", !formik.values.newPasswordVisible)}
                                        className="position-absolute border-0 bg-transparent d-flex"
                                        style={{
                                            top: "30px",
                                            right: "10px",
                                            transform: "translateY(-19px)",
                                            color: "#828282",
                                            fontSize: "20px",
                                        }}
                                    >
                                        {formik.values.newPasswordVisible ? <FiEye /> : <FiEyeOff />}
                                    </button>
                                    {formik.touched.password && formik.errors.password ? (
                                        <div className="invalid-feedback">{formik.errors.password}</div>
                                    ) : null}
                                </div>
                                <PasswordStrengthBar password={formik.values.password} className="passwordStrengthBar" />

                                <p className="Input-para pt-2 Input-field">
                                Your password must contain at least 1 capital letter, 1 special character or symbol, and meet our specified criteria.
                                </p>
                            </div>

                            <div className="form-row">
                                <div className="position-relative" style={{ maxWidth: "400px" }}>
                                    <input
                                        type={formik.values.confirmPasswordVisible ? "text" : "password"}
                                        name="confirm_password"
                                        id="confirm-password"
                                        placeholder="Confirm password*"
                                        className={`form-control mt-3 Input-field ${formik.touched.confirm_password && formik.errors.confirm_password ? "is-invalid" : ""}`}
                                        value={formik.values.confirm_password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => formik.setFieldValue("confirmPasswordVisible", !formik.values.confirmPasswordVisible)}
                                        className="position-absolute border-0 bg-transparent d-flex"
                                        style={{
                                            top: "30px",
                                            right: "10px",
                                            transform: "translateY(-19px)",
                                            color: "#828282",
                                            fontSize: "20px",
                                        }}
                                    >
                                        {formik.values.confirmPasswordVisible ? <FiEye /> : <FiEyeOff />}
                                    </button>
                                    {formik.touched.confirm_password && formik.errors.confirm_password ? (
                                        <div className="invalid-feedback">{formik.errors.confirm_password}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="d-grid gap-2 mt-2 Input-field" style={{ paddingTop: "16px" }}>

                                <button
                                    className={`Continue-btn ${!isFormIncomplete ? "active" : "disabled"}`}
                                    type="submit"
                                    onClick={handleNewSubmit}
                                >
                                    Continue
                                </button>

                                <button className="Login_btn" type="button" onClick={() => navigate("/login")}>
                                    Login
                                </button>
                                <p className="Input-para pt-2 Input-field">
                                    By clicking continue, it indicates you have read and agreed to{" "}
                                    <span className="span-text"> terms and conditions </span> and{" "}
                                    <span className="span-text"> privacy policy </span> of Bookeez application.
                                </p>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-6 flexorder2 ">
                        <img className="placeholder-img" src={placeholder} alt="Placeholder" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Signup






import { Link } from 'react-router-dom';
import carbonsupply from "../assets/images/carbonsupply.png";
import stramlinecostomer from "../assets/images/stramlinecostomer.png";
import supplierBlue from "../assets/images/supplierBlue.png";
import costomerBlue from "../assets/images/costomerBlue.png";
import './sidebar.scss';

const AdminSidebar = () => {
    const path = window.location.pathname;
    return (

        <>
            <div className="sidebar_open" >
                <div className='customer_supplier_link'>
                    <ul>
                        <li className=''>
                            <Link to="/accountant-dashboard" className={path === "/accountant-dashboard" ? "mm-actives" : ""}  >
                                <div className='d-flex align-items-center accrodian_content_link' style={{ paddingTop: "13px" }}>
                                    <img
                                        src={path === "/accountant-dashboard" ? carbonsupply : supplierBlue}
                                        alt=""
                                        className='accordian_images'
                                        style={{ width: "24px", height: "24px" }}
                                    />
                                    <span className='onclick_sidebars' >Dashboard</span>
                                </div>
                            </Link>
                        </li>
                        <li className='' >
                            <Link to="/inviteaccountant" className={path === "/inviteaccountant" ? "mm-actives" : ""}    >
                                <div className='d-flex align-items-center accrodian_content_link' style={{ paddingTop: "13px" }}>
                                    <img
                                        src={path === "/inviteaccountant" ? stramlinecostomer : costomerBlue}
                                        alt=""
                                        className='accordian_images'
                                        style={{ width: "24px", height: "24px" }}
                                    />
                                    <span className='onclick_sidebars' >Invite client</span>
                                </div>
                            </Link>
                        </li>
                        <li className='' >
                            <Link to="/submission-history" className={path === "/submission-history" ? "mm-actives" : ""}    >
                                <div className='d-flex align-items-center accrodian_content_link' style={{ paddingTop: "13px" }}>
                                    <img
                                        src={path === "/submission-history" ? stramlinecostomer : costomerBlue}
                                        alt=""
                                        className='accordian_images'
                                        style={{ width: "24px", height: "24px" }}
                                    />
                                    <span className='onclick_sidebars' >Billing</span>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>

            </div>
        </>
    );
};

export default AdminSidebar;




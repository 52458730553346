

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import paymoney from "../assets/images/paymoney.png";
import carbonsupply from "../assets/images/carbonsupply.png";
import stramlinecostomer from "../assets/images/stramlinecostomer.png";
import marklist from "../assets/images/marklist.png";
import dashbord from "../assets/images/Dashboard.png";
import cost from "../assets/images/cost.png";
import supplierBlue from "../assets/images/supplierBlue.png";
import costomerBlue from "../assets/images/costomerBlue.png";
import './sidebar.scss';

const Sidebar = () => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const data = JSON.parse(localStorage.getItem('message'));

  const adminClientId = localStorage.getItem('admin_client_id');
  const ClientType = JSON.parse(localStorage.getItem('user_type'))?.user_type;


  const handleAccordionToggle = (key) => {
    let acc = document.querySelectorAll('.accordion-collapse')[0]
    let accor = document.querySelectorAll('.accordion-button')[0]
    acc.classList.remove('show')
    accor.classList.remove('accordion-button1')

    setIsAccordionOpen(isAccordionOpen === key ? null : key);
  };



  const handleDashboard = () => {
    navigate('/dashboard')
  }

  const handleNavigate = () => {
    let acc = document.querySelectorAll('.accordion-collapse')[0]
    let accor = document.querySelectorAll('.accordion-button')[0]
    acc.classList.remove('show')
    accor.classList.remove('accordion-button1')
    navigate('/costs/processing')

  }

  const handleMoreNavigate = () => {
    let acc = document.querySelectorAll('.accordion-collapse')[0]
    let accor = document.querySelectorAll('.accordion-button')[0]
    acc.classList.remove('show')
    accor.classList.remove('accordion-button1')
    navigate('/more-lists/categories')
  }

  const handleRevenueNavigate = () => {
    let acc = document.querySelectorAll('.accordion-collapse')[0]
    let accor = document.querySelectorAll('.accordion-button')[0]
    acc.classList.remove('show')
    accor.classList.remove('accordion-button1')
    navigate('/revenue/processing')
  }

  useEffect(() => {
    let accor = document.querySelectorAll('.accordion-button')[0]
    let acc = document.querySelectorAll('.accordion-collapse')[0]
    let newPath = path?.split('/')

    if (path === '/dashboard') {
      accor?.classList.add('accordion-button1')
      accor?.setAttribute("aria-expanded", true);
      acc?.classList.add('show')
    }

    return () => {
      let newPath = path?.split('/')
      if (newPath.includes('dashboard')) {
        accor?.classList.remove('accordion-button1')
        accor?.setAttribute("aria-expanded", false);
      }
    };
  }, [handleDashboard, handleNavigate]);


  useEffect(() => {
    let accor = document.querySelectorAll('.accordion-button')[0]
    let acc = document.querySelectorAll('.accordion-collapse')[0]
    let newPath = path?.split('/')

    if (path === '/dashboard') {
      accor?.setAttribute("aria-expanded", false);
    }

    return () => {
      accor?.setAttribute("aria-expanded", false);
    };
  }, []);


  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      .sidebar_open .accordion .accordion-item button[aria-expanded=true] {
        padding-bottom: 0px !important;
      }
    `;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);


  const path = window.location.pathname;
  const navigate = useNavigate()


  useEffect(() => {
    let accor = document.querySelectorAll('.accordion-button')[0];
    let acc = document.querySelectorAll('.accordion-collapse')[0];
    let newPath = path?.split('/');

    if (accor && acc && path === '/inviteaccountant' && newPath.includes('/inviteaccountant') && newPath.includes('inviteaccountant')) {
      accor?.setAttribute("aria-expanded", false);
    }
    return () => {
      if (accor) accor?.setAttribute("aria-expanded", true);
    };
  }, []);


  return (

    <>
      {/* {data?.user_data?.is_client || adminClientId ? ( */}
      {true ? (
        <div className="sidebar_open"  >
          <Accordion defaultActiveKey="0" onSelect={handleAccordionToggle} activeKey={isAccordionOpen}  >
            <Accordion.Item eventKey="0">
              <div className='d-flex align-items-center accrodian_content'>
                <Accordion.Header onClick={handleDashboard}>
                  <img src={dashbord} className={`${path === "/dashboard" ? "accordian_images grey bg_dash mm-active" : "accordian_images grey white"}`} alt="Dashboard" onClick={handleDashboard} />
                  <Link to="/dashboard" className={`${path === "/dashboard" ? "mm-active" : ""} no-text-decoration`}>
                    <span className='onclick_sidebar' onClick={handleDashboard} >Dashboard</span>
                  </Link>
                </Accordion.Header>
              </div>
              <Accordion.Body>
                <ul>
                  <li className='subMenuStyle'>
                    <Link to="/dashboard/accountsetup" className={path === "/dashboard/accountsetup" ? "mm-active" : ""} >Account setup</Link>
                  </li>

                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" >
              <div className='d-flex align-items-center accrodian_content'>
                <Accordion.Header onSelect={handleAccordionToggle} onClick={handleNavigate} >
                  <img src={cost} className='accordian_images grey' alt="Dashboard" />
                  <Link to="/costs/processing" className={`${path === "/cost/processing" ? "mm-active" : ""} no-text-decoration`}>
                    <span className='onclick_sidebar'>Costs</span>
                  </Link>
                </Accordion.Header>
              </div>
              <Accordion.Body>
                <ul>
                  <li>
                    <Link to="/costs/processing" className={path === "/costs/processing" ? "mm-active" : ""} >Processing</Link>
                  </li>
                  <li>
                    <Link to="/costs/review" className={path === "/costs/review" ? "mm-active" : ""}>Review</Link>
                  </li>
                  <li>
                    <Link to="/costs/ready" className={path === "/costs/ready" ? "mm-active" : ""}> Ready</Link>
                  </li>
                  <li>
                    <Link to="/costs/archive" className={path === "/costs/archive" ? "mm-active" : ""} > Archive</Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" >
              <div className='d-flex align-items-center accrodian_content'>
                <Accordion.Header onSelect={handleAccordionToggle} onClick={handleRevenueNavigate} >
                  <img src={paymoney} className='accordian_images grey' alt="Dashboard" />
                  <Link to="/revenue/processing" className={`${path === "/revenu/processing" ? "mm-active" : ""} no-text-decoration`}>
                    <span className='onclick_sidebar'>Revenue</span>
                  </Link>
                </Accordion.Header>
              </div>
              <Accordion.Body>
                <ul>
                  <li>
                    <Link to="/revenue/processing" className={path === "/revenue/processing" ? "mm-active" : ""}>Processing</Link>
                  </li>
                  <li>
                    <Link to="/revenue/review" className={path === "/revenue/review" ? "mm-active" : ""} >Review</Link>
                  </li>
                  <li>
                    <Link to="/revenue/ready" className={path === "/revenue/ready" ? "mm-active" : ""}> Ready</Link>
                  </li>
                  <li>
                    <Link to="/revenue/archive" className={path === "/revenue/archive" ? "mm-active" : ""} > Archive</Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <div className='customer_supplier_link'>
              <ul >
                <li className=''>
                  <Link to="/suppliers" className={path === "/suppliers" ? "mm-actives" : ""} onClick={() => handleAccordionToggle('supplierAccordion')}>
                    <div className='d-flex align-items-center accrodian_content_link' style={{ paddingTop: "13px" }}>
                      <img
                        src={path === "/suppliers" ? carbonsupply : supplierBlue}
                        alt=""
                        className='accordian_images'
                        style={{ width: "24px", height: "24px" }}
                      />
                      <span className='onclick_sidebars' >Suppliers</span>
                    </div>
                  </Link>
                </li>
                <li className='' >
                  <Link to="/customers" className={path === "/customers" ? "mm-actives" : ""} onClick={() => handleAccordionToggle('customerAccordion')}    >
                    <div className='d-flex align-items-center accrodian_content_link' style={{ paddingTop: "13px" }}>
                      <img
                        src={path === "/customers" ? stramlinecostomer : costomerBlue}
                        alt=""
                        className='accordian_images'
                        style={{ width: "24px", height: "24px" }}
                      />
                      <span className='onclick_sidebars' >Customers</span>
                    </div>
                  </Link>
                </li>
                {ClientType === 2 && (
                  <li className='' >
                    <Link to="/manage-teams" className={path === "/manage-teams" ? "mm-actives" : ""} onClick={() => handleAccordionToggle('customerAccordion')}    >
                      <div className='d-flex align-items-center accrodian_content_link' style={{ paddingTop: "13px" }}>
                        <img
                          src={path === "/manage-teams" ? stramlinecostomer : costomerBlue}
                          alt=""
                          className='accordian_images'
                          style={{ width: "24px", height: "24px" }}
                        />
                        <span className='onclick_sidebars' >Manage Teams</span>
                      </div>
                    </Link>
                  </li>
                )}

              </ul>
            </div>
            {ClientType === 3 || ClientType === 2 ? (
              <Accordion.Item eventKey="5" >
                <div className='d-flex align-items-center accrodian_content' style={{ paddingTop: "0px" }}>
                  <Accordion.Header onSelect={handleAccordionToggle} onClick={handleMoreNavigate}>
                    <img src={marklist} className='accordian_images grey' alt="Dashboard" />
                    <Link to="/more-lists/categories" className={`${path === "/more-list/categories" ? "mm-active" : ""} no-text-decoration`}>
                      <span className='onclick_sidebar'>More lists</span>
                    </Link>
                  </Accordion.Header>
                </div>
                <Accordion.Body>
                  <ul>
                    <li>
                      <Link to="/more-lists/categories" className={path === "/more-lists/categories" ? "mm-active" : ""}>Group & Account</Link>
                    </li>
                    <li>
                      <Link to="/more-lists/tax" className={path === "/more-lists/tax" ? "mm-active" : ""} >Tax</Link>
                    </li>
                    <li>
                      <Link to="/more-lists/project" className={path === "/more-lists/project" ? "mm-active" : ""} >Project</Link>
                    </li>

                    <li>
                      <Link to="/more-lists/system-setting" className={path === "/more-lists/system-setting" ? "mm-active" : ""}> System settings</Link>
                    </li>
                    <li>
                      <Link to="/more-lists/notification" className={path === "/more-lists/notification" ? "mm-active" : ""} >Notifications</Link>
                    </li>
                    {/* <li>
                      <Link to="/manage-teams" className={path === "/manage-teams" ? "mm-active" : ""} >Teams</Link>
                    </li> */}
                    {/* <li>
                    <Link to="/more-lists/subscription" className={path === "/more-lists/subscription" ? "mm-active" : ""} >Subscription</Link>
                  </li> */}

                    {/* <li>
                    <Link to="/more-lists/accountant" className={path === "/more-lists/accountant" ? "mm-active" : ""} >Client</Link>
                  </li> */}
                    {/* <li>
                    <Link to="/more-lists/submission-history" className={path === "/more-lists/submission-history" ? "mm-active" : ""} >Submission history</Link>
                  </li>
                  <li>
                    <Link to="/more-lists/my-details" className={path === "/more-lists/my-details" ? "mm-active" : ""} >My details</Link>
                  </li>
                  <li>
                    <Link to="/more-lists/manage-payment-submission" className={path === "/more-lists/manage-payment-submission" ? "mm-active" : ""} >Manage payment submissions</Link>
                  </li> */}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ) : ''}
          </Accordion>
        </div>
      ) : (
        <div className="sidebar_open" >
          <div className='customer_supplier_link'>
            <ul>
              <li className=''>
                <Link to="/dashboard-client" className={path === "/dashboard-client" ? "mm-actives" : ""}  >
                  <div className='d-flex align-items-center accrodian_content_link' style={{ paddingTop: "13px" }}>
                    <img
                      src={path === "/dashboard-client" ? carbonsupply : supplierBlue}
                      alt=""
                      className='accordian_images'
                      style={{ width: "24px", height: "24px" }}
                    />
                    <span className='onclick_sidebars' >Dashboard</span>
                  </div>
                </Link>
              </li>
              <li className='' >
                <Link to="/inviteaccountant" className={path === "/inviteaccountant" ? "mm-actives" : ""}    >
                  <div className='d-flex align-items-center accrodian_content_link' style={{ paddingTop: "13px" }}>
                    <img
                      src={path === "/inviteaccountant" ? stramlinecostomer : costomerBlue}
                      alt=""
                      className='accordian_images'
                      style={{ width: "24px", height: "24px" }}
                    />
                    <span className='onclick_sidebars' >Invite client</span>
                  </div>
                </Link>
              </li>
            </ul>
          </div>

        </div>)
      }
    </>
  );
};

export default Sidebar;




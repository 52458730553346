

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordRequest } from '../../redux/actions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './forgotpassword.scss';
import LoginSignupHeader from '../loginSignupHeader/LoginSignupHeader';
import passwordImage from '../../assets/images/passwordImage.png';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const response = useSelector((state) => state)



  useEffect(() => {
    if (response?.forgotPassword?.forgotPassword?.data) {
      toast.success(response.forgotPassword.forgotPassword.data.message)
    } 
    
  }, [response?.forgotPassword?.forgotPassword?.data?.message])

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('*required'),
  });


  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,

    onSubmit: async (values, { resetForm }) => {
      dispatch(forgotPasswordRequest(values.email));
      resetForm()
      return
    },
  });


  return (
    <>
      <LoginSignupHeader />
      <div className="main_section">
        <div className="row g-0 forgotpasswordcontainer">
          <div className="col-lg-6 col-12" style={{ marginTop: '10px' }}>
            <h2 className="heading-forgot">Forgot Your Password</h2>
            <h5 className="heading-text">
              Please enter your email address. You will receive a link to create a new password via email{' '}
            </h5>
            <div className="forgotpassword_from">
              <form onSubmit={formik.handleSubmit} className="forgotpassword" ref={formik.form}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label heading-text" style={{ marginTop: '10px' }}>
                    Email address
                  </label>
                  <input
                    type="email"
                    className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    placeholder="Enter your email address"
                    aria-describedby="emailHelp"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="invalid-feedbacks">{formik.errors.email}</div>
                  )}
                </div>
                <button type="submit"
                  value="Reset Password"
                  className={`ResetPasswordbutton mt-2 form-control ${formik.values.email ? 'active' : ''}`}
                  id="exampleFormControlInput1">Reset Password</button>

                <Link to="/login" style={{ textDecoration: "none" }}>
                  <h6 className='BackToLoginButton' style={{ textAlign: "center", marginTop: "15px", color: "#328ED9", fontSize: "14px" }}>Back to Login</h6>
                </Link>
              </form>
            </div>
          </div>
          <div className="col-md-6 PasswordImage" style={{ marginTop: '0px' }}>
            <img src={passwordImage} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;


import React, { useEffect } from 'react'
import './CreateNewPassword.scss'
import LoginSignupHeader from '../loginSignupHeader/LoginSignupHeader'
import passwordImage from "../../assets/images/passwordImage.png"
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createNewPasswordRequest, updateClientPasswordRequest } from '../../redux/actions'
import PasswordStrengthBar from 'react-password-strength-bar'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { toast } from 'react-toastify'
import _FetchApi from '../../services/fetchservice'
import { BASE_URL } from '../../constant/common'
import { PostUpdateTeamMemberPassword } from '../../services/api'


const CreateNewPassword = () => {
    const { state } = useLocation()

    const validationSchema = Yup.object({
        password: Yup.string()
            .required("*required")
            .test(
                value => {
                    const hasCapitalLetter = /[A-Z]/.test(value);

                    const hasNumber = /\d/.test(value);

                    const hasSpecialCharacter = /[!@#$%^&*]/.test(value);

                    return hasCapitalLetter && hasNumber && hasSpecialCharacter;
                }
            ),
        confirm_password: Yup.string()
            .oneOf([Yup.ref("password")], ("passwords do not match , try again"))
            .required("re-enter password")

    });

    const navigate = useNavigate()
    const reduxDetails = useSelector(state => state?.updateClientPasswordReducer?.updatePassword)
    // console.log(reduxDetails)

    useEffect(() => {
    }, [reduxDetails])

    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: '',
            newPasswordVisible: false,
            confirmPasswordVisible: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (state.requestValue === "team") {

                const data = {
                    code: state.token,
                    password: values.password
                }

                PostUpdateTeamMemberPassword(data).then((res) => {
                    if (res.code === 200) {
                        toast.success(res.message);
                        navigate('/login');
                    } else {
                        toast.error(res.message);
                    }
                }).catch((err) => {
                    console.log('err', err);
                })
            } else {
                if (state?.loc?.pathname === '/auth/update_password/') {
                    let data = await _FetchApi("POST", `${BASE_URL}update_client_password/`, { code: state.token, password: values.password });
                    if (data) {
                        localStorage.setItem('clientData', JSON.stringify(data))
                    }
                    if (state.token && values.password) {

                        console.log(reduxDetails, 'reduxDetails')

                        if (data?.data?.code == 200) {
                            navigate('/login');
                        } else if (data?.data?.code === 400) {
                            toast.error(data?.data?.message);
                        }
                    }
                }



                if (state.loc.pathname == '/auth/set_password/') {
                    dispatch(createNewPasswordRequest({ code: state.token, password: values.password }))
                    if (state.token, password) {
                        navigate('/login');
                    }
                }
            }

        },
    });



    const dispatch = useDispatch()

    let formikDAta = formik.values
    let { password, confirm_password } = formikDAta
    let body = { password, confirm_password }

    const isFormIncomplete = Object.values(body).some(
        (value) => {
            return value === ""
        }
    );

    let abc = document.getElementsByClassName('passwordStrengthBar');


    useEffect(() => {
        localStorage.removeItem('clientData')
        if (abc.length > 0) {
            let passwordStrengthBar = abc[0];

            let pElement = passwordStrengthBar.querySelector('p');
            if (pElement) {
                pElement.remove();
            }

            let remainingBars = passwordStrengthBar.querySelectorAll('div[style*="background-color"]');
            remainingBars.forEach(bar => {
                bar.style.height = '5px';
                bar.style.color = 'black';
            });
        }
    })


    console.log('state', state)
    return (
        <>
            <LoginSignupHeader />
            <div className='main_sectionsCreate' style={{ padding: '40px' }}>
                <div className="row g-0 createpasswordcontainer">
                    <div className="col-lg-6 col-12 ">
                        <h2 className="heading-create-password mt-4">Create a new password</h2>
                        <div className='create_password_from'>
                            <form className='createpassword' onSubmit={formik.handleSubmit}>
                                <div className="mb-3">
                                    <div className="form-row  " >
                                        <div className="position-relative" style={{ maxWidth: "400px", fontFamily: "Roboto" }}>
                                            <input
                                                type={formik.values.newPasswordVisible ? "text" : "password"}
                                                name="password"
                                                id="new-password"
                                                placeholder="Create password"
                                                className={`form-control mt-3 Input-field ${formik.touched.password && formik.errors.password ? "is-invalid" : ""}`}
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <button
                                                type="button"
                                                onClick={() => formik.setFieldValue("newPasswordVisible", !formik.values.newPasswordVisible)}
                                                className="position-absolute border-0 bg-transparent d-flex"
                                                style={{
                                                    top: "30px",
                                                    right: "10px",
                                                    transform: "translateY(-19px)",
                                                    color: "#828282",
                                                    fontSize: "20px",
                                                }}
                                            >
                                                {formik.values.newPasswordVisible ? <FiEye /> : <FiEyeOff />}
                                            </button>
                                            {formik.touched.password && formik.errors.password && (
                                                <div className="invalid-feedback">{formik.errors.password}</div>
                                            )}
                                        </div>
                                        <PasswordStrengthBar password={formik.values.password} className="passwordStrengthBar" />
                                        <p className='Input-para pt-2 password-text'>Your password must contain at least 1 capital letter, 1 special character or symbol, and meet our specified criteria</p>
                                    </div>

                                    <div className="form-row ">
                                        <div className="position-relative" style={{ maxWidth: "400px", fontFamily: "Roboto" }}>
                                            <input
                                                type={formik.values.confirmPasswordVisible ? "text" : "password"}
                                                name="confirm_password"
                                                id="confirm-password"
                                                placeholder="Confirm password"
                                                className={`form-control mt-3 Input-field ${formik.touched.confirm_password && formik.errors.confirm_password ? "is-invalid" : ""}`}
                                                value={formik.values.confirm_password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <button
                                                type="button"
                                                onClick={() => formik.setFieldValue("confirmPasswordVisible", !formik.values.confirmPasswordVisible)}
                                                className="position-absolute border-0 bg-transparent d-flex"
                                                style={{
                                                    top: "30px",
                                                    right: "10px",
                                                    transform: "translateY(-19px)",
                                                    color: "#828282",
                                                    fontSize: "20px",
                                                }}
                                            >
                                                {formik.values.confirmPasswordVisible ? <FiEye /> : <FiEyeOff />}
                                            </button>
                                            {formik.touched.confirm_password && formik.errors.confirm_password && (
                                                <div className="invalid-feedback">{formik.errors.confirm_password}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className={`CreatePasswordbutton mt-2 form-control ${!isFormIncomplete ? "active" : "disabled"}`}
                                    id="exampleFormControlInput1"
                                // disabled={formik.isSubmitting}
                                >
                                    Set Password
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6 createpasswordImage" style={{ marginTop: "0px" }}>
                        <img src={passwordImage} alt="" />
                    </div>
                </div >

            </div >

        </>
    )
}
export default CreateNewPassword


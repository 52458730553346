import React, { useEffect } from 'react';
import "./about.scss"
import Aboutimg from "../../assets/images/Aboutimg.png"
import aboutcardimg1 from "./../../assets/images/aboutcardimg1.png"
import aboutcard2 from "./../../assets/images/aboutcard2.png"
import aboutcard3 from "./../../assets/images/aboutcard3.png"
import { MdModeComment } from "react-icons/md";
import { MdOutlineScreenShare } from "react-icons/md";
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { Link, useLocation } from 'react-router-dom';



const About = () => {
    const { pathname } = useLocation();


    const cardData = [
        {
            id: 1,
            image: aboutcardimg1,
            title: 'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum',
            para:
                'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet...',
            date: '23 November 2023',
            comments: 23,
            shares: 0,
        },
        {
            id: 1,
            image: aboutcard2,
            title: 'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum',
            para:
                'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet...',
            date: '23 November 2023',
            comments: 23,
            shares: 0,
        },
        {
            id: 1,
            image: aboutcard3,
            title: 'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum',
            para:
                'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet...',
            date: '23 November 2023',
            comments: 23,
            shares: 0,
        },


    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    
    return (

        <div>
            <Header />
            <div className='main_section'>
                <div className="row align-items-center about_content"  >
                    <div className="col-md-6">
                        <h2 className='card-heading' style={{ maxWidth: "14ch" }}> What do you get with <span className='span-heading banner-heading'>Bookeez</span><span className='banner-question'>?</span>
                        </h2>
                        <img src={Aboutimg} alt='bannerimg' className='bannerimg' />
                    </div>
                    <div className="col-md-6 " style={{ marginBottom: "3rem" }}>
                        <p className='card-para'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia explicabo dolores repudiandae iusto fugiat exercitationem, eos at provident deleniti officia alias cupiditate corrupti dolorum ipsum sint tenetur aperiam expedita quidem voluptate aut, earum voluptatibus. Commodi dolorem, totam, veniam at unde asperiores odio molestiae mollitia vel error officiis, blanditiis autem! Enim dolore dignissimos placeat quidem aperiam voluptatum nostrum labore cum magnam ipsa adipisci, odit beatae facilis iste. Cumque quos libero blanditiis natus, velit fugit debitis placeat explicabo!Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet tincidunt eu a.  </p>
                        <p className='card-para'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe nisi dolore nostrum praesentium dicta voluptatem blanditiis ducimus officia? Facere atque ratione, labore in hic quibusdam iure expedita assumenda cumque magnam.Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet tincidunt eu a.Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet tincidunt eu a.</p>
                        <p className='card-para'>Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet tincidunt eu a.Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet tincidunt eu a.Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet tincidunt eu a.</p>
                        <p className='card-para'> Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet tincidunt eu a.Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet tincidunt eu a.</p>
                    </div>
                </div>

                <div className="container mt-2">
                    <div className="row row-cols-1 row-cols-md-4 g-4 pt-3 justify-content-center">
                        {cardData.map((data) => (
                            <div className="col mb-4 card-hover" key={data.id} style={{ width: '350px' }}>
                                <div className="card h-100">
                                    <div className="cardimages">
                                        <img src={data.image} className="card-img-top p-3 image" alt="..." />
                                    </div>
                                    <div className="card-body" >
                                        <h4 className="card-title">{data.title}</h4>
                                        <p className="card-text card-para">{data.para}</p>
                                        <hr />
                                        <div className="d-flex justify-content-center card-date ">
                                            <p style={{ font: "400 14px 'Roboto" }}>{data.date}</p>
                                            <p className='card-comments ps-4'>
                                                <MdModeComment className='card-icon' /> {data.comments}
                                            </p>
                                            <p className='card-comments'>
                                                <MdOutlineScreenShare className='card-icon' /> {data.shares}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <Link to='/blogs' style={{ textDecoration: "none" }}>
                    <div className='d-flex justify-content-center  pb-3 pt-4'>
                        <button className='card-btn btn border rounded'>Read our other blogs</button>
                    </div>
                </Link>
            </div>
            <Footer />

        </div>

    );
}

export default About;

import React, { useEffect } from 'react';
import "./homepage.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import Group from "../../assets/images/Group.png";
import hand from "../../assets/images/hand.png";
import setting from "../../assets/images/setting.png";
import share from "../../assets/images/share.png";
import money from "../../assets/images/money.png";
import finanace from "../../assets/images/finanace.png";
import reporting from "../../assets/images/reporting.png";
import bookepping from "../../assets/images/bookepping.png";
import account from "../../assets/images/account.png";
import plus from "../../assets/images/plus.png";
import bussiness from "../../assets/images/bussiness.png"
import meeting from "../../assets/images/meeting.png"
import lapy from "../../assets/images/lapy.png"
import grouplaptop from "../../assets/images/groplaptop.png"
import frequent from "../../assets/images/frequent.png"
import { IoIosAddCircle } from "react-icons/io";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { MdModeComment, MdOutlineScreenShare } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';




const Homepage = () => {
    const { pathname } = useLocation();


    const cardData = [
        {
            id: 1,
            image: lapy,
            title: 'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum',
            para:
                'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet...',
            date: '23 November 2023',
            comments: 23,
            shares: 0,
        },
        {
            id: 1,
            image: meeting,
            title: 'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum',
            para:
                'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet...',
            date: '23 November 2023',
            comments: 23,
            shares: 0,
        },
        {
            id: 1,
            image: bussiness,
            title: 'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum',
            para:
                'Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum Lorem ipsum dolor sit amet consectetur. Amet...',
            date: '23 November 2023',
            comments: 23,
            shares: 0,
        },


    ];
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <>
            <Header />
            <div className='main_section' >
                <div className="row" >
                    <div className="col-md-6" >
                        <div className='Finances_content d-flex  flex-column justify-content-between  financeses' >
                            <h2 className='card-heading ' style={{ maxWidth: "17ch", marginTop: "40px" }}>Elevate your business journey to new heights!</h2>
                            <p className='card-para simply_text'>Simplify Your Finances! Experience seamless bookkeeping with our user-friendly application. Click here to start organizing your financial journey effortlessly.</p>
                            <Link to='/login'> <button className='get_start_btn '>Get started</button></Link>

                        </div>
                    </div>
                    <div className="col-md-6 group_img" >
                        <img src={Group} alt="" className="img_content" />
                    </div>

                </div>
                <div className='row d-flex justify-content-end  pt-5' style={{ marginTop: "109px" }}>
                    <div className='col-md-6'>
                        <h2 className='card-heading' style={{ maxWidth: "17ch" }}>Realtime insights at your fingertips!</h2>
                    </div>
                    <div className='col-md-6 align-self-end'>
                        <p className='card-para  para_top' style={{ maxWidth: "67ch", float: "right" }}>Expertise that Counts. Impeccable Books. Your small business team, dedicated to importing bank statements and delivering accurate financial statements monthly.</p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "#EAF4FF" }}>
                <div className='row mt-3 d-flex justify-content-center  pt-4  g-0' style={{ maxWidth: "90%", margin: "0 auto" }}>
                    <div className='col-lg-3  col-md-6 col-sm-6 text-center share-link-item'>
                        <img src={setting} alt="Setting" />
                        <p className='card-para-title mt-4'>Gain insights instantly</p>
                        <p className='card-para paras_top mx-auto' style={{ maxWidth: "24ch" }}>Streamlined Transactions. Simplified Processes. Count on our dedicated team to curate a hassle-free payment experience, making financial transactions easier and more efficient.</p>
                    </div>
                    <div className='col-lg-3  col-md-6 col-sm-6 text-center share-link-item'>
                        <img src={hand} alt="Hand" />
                        <p className='card-para-title mt-4 mx-auto' style={{ maxWidth: "25ch" }}>Financial Clarity at Your Fingertips</p>
                        <p className='card-para para_top mx-auto' style={{ maxWidth: "26ch" }}>Access easy-to-understand invoices that provide a clear breakdown of your financial transactions, promoting a stress-free experience.</p>
                    </div>
                    <div className='col-lg-3  col-md-6 col-sm-6 text-center share-link-item'>
                        <img src={share} alt="Share" />
                        <p className='card-para-title mt-4 mx-auto' style={{ maxWidth: "23ch" }}>Bookkeeping automation for efficiency</p>
                        <p className='card-para para_top mx-auto' style={{ maxWidth: "23ch" }}>Empower Your Financial Journey: With our committed small business experts at your side, enjoy the ease of importing bank statements and receiving accurate financial statements every month—a testament to our dedication to your financial success.</p>
                    </div>
                    <div className='col-lg-3  col-md-6 col-sm-6 text-center share-link-item'>
                        <img src={money} alt="Money" />
                        <p className='card-para-title mt-4 mx-auto' style={{ maxWidth: "23ch" }}>Track your payables effectively</p>
                        <p className='card-para para_top mx-auto' style={{ maxWidth: "29ch" }}>Effortless Financial Precision: Transform your approach to payables tracking with our accurate and user-friendly system, providing you with the tools for effective financial control and management.</p>
                    </div>
                </div>
            </div>

            <div className='main_section '>
                <div className='row mt-4'>
                    <div className='col-md-6 '>
                        <h2 className='card-heading' style={{ maxWidth: "17ch" }}>
                            Know more about
                            <div className='span-heading'>Bookeez</div>
                        </h2>
                        <p className='card-para ' style={{ marginTop: "50px" }} >Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet tincidunt eu a.Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet tincidunt eu a.
                            Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet tincidunt eu a.</p>
                        <p className='card-para'> Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet tincidunt eu a.eu a.Lorem ipsum dolor sit amet consectetur. Nisi consequat pellentesque elementum dolor nunc dis non quam nullam. In at molestie fusce ultrices neque euismod. Orci duis eleifend maecenas nulla. Purus nibh mauris vestibulum amet eu eu a.</p>
                    </div>
                    <div className='col-md-6'>
                        <img src={grouplaptop} alt="" className='grouplaptop_img  mt-4 ps-5 ms-2' />
                    </div>
                </div>
            </div>

            <div className='client_information_section mt-0 pt-0'>
                <div className='container g-0 '>
                    <h2 className='client_heading pt-5 mb-5'>Effortlessly handle your practice and client information for seamless management.</h2>
                    <div className='d-md-flex  client_info_box mt-4'>
                        <img src={finanace} alt="" className='client_img' />
                        <div>
                            <div className='d-flex finacial-img'>
                                <div><img src={plus} alt="" className='plus_img' /></div>
                                <div className='ps-3'>
                                    <span><h3 className='subheading_client'>Understand your finances</h3></span>
                                    <p className='card-para' style={{ marginTop: "40px" }} >Explore insightful resources, tutorials, and expert guidance designed to demystify financial concepts. Whether you're a beginner or an experienced user, empower yourself with the knowledge to effectively manage and understand your finances. Simplify financial complexities with our comprehensive resources, ensuring you have the tools to make informed decisions. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='d-md-flex client_info_box mt-4 pt-3'>
                        <img src={account} alt="" className='client_img' />
                        <div>
                            <div className='d-flex finacial-img '>
                                <div><img src={plus} alt="" className='plus_img' /></div>
                                <div className='ps-3'>
                                    <span><h3 className='subheading_client'>Accountant</h3></span>
                                    <p className='card-para' style={{ marginTop: "40px" }} >Our accountant services on this Bookeez website offer a specialized touch to your financial management. Our skilled accountants meticulously analyze and interpret your financial data, ensuring accuracy, compliance, and strategic insights. From balancing the books to providing strategic financial advice, trust our expert accountants to elevate your financial success.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='d-md-flex  client_info_box'>
                        <img src={bookepping} alt="" className='client_img' />
                        <div>
                            <div className='d-flex finacial-img'>
                                <div><img src={plus} alt="" className='plus_img' /></div>
                                <div className='ps-3'>
                                    <span><h3 className='subheading_client'>Bookkeeping</h3></span>
                                    <p className='card-para' style={{ marginTop: "40px" }} >Our bookkeeping services provide a meticulous and streamlined approach to organizing your financial data. From precise transaction recording to insightful financial reporting, we tailor our services to ensure your business's financial health is in expert hands. Simplify your bookkeeping, gain clarity in your financial landscape, and make informed decisions with our dedicated and reliable bookkeeping service. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='d-md-flex client_info_box pt-3'>
                        <img src={reporting} alt="" className='client_img' />
                        <div>
                            <div className='d-flex finacial-img '>
                                <div><img src={plus} alt="" className='plus_img' /></div>
                                <div className='ps-3'>
                                    <span><h3 className='subheading_client'>Powerful financial reporting</h3></span>
                                    <p className='card-para' style={{ marginTop: "40px" }}  >Our Financial Reporting Section goes beyond numbers, providing you with a robust platform to harness the true potential of your financial data. Dive into dynamic reports, customizable analytics, and visually compelling insights. Transform raw data into actionable intelligence, empowering you to make informed decisions that drive your business forward. Experience financial reporting that is not just comprehensive but a powerful catalyst for strategic growth. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='main_section'>
                <div className='row  mt-4'>
                    <div className='col-md-6 frequent_accrodian' >
                        <h2 className='card-heading frequent_accrodian' style={{ maxWidth: "17ch" }}> Frequently Asked Questions</h2>
                        <img src={frequent} alt="" className='frequent_accrodian' />
                    </div>

                    <div className="col-md-6" style={{ borderLeft: '1px solid #D9D9D9' }}>
                        <div  >

                            <div className="accordion-item pb-3" style={{ paddingLeft: '30px' }}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button">
                                        <div className='d-flex'>
                                            <div>
                                                <IoIosAddCircle className='circle_btn' />
                                            </div>
                                            <div>
                                                <div className='d-flex ms-2'>
                                                    <span style={{ textAlign: 'left', borderBottom: '1px solid #ccc', paddingBottom: '5px' }} >
                                                        <p className='accordion-para' style={{ fontSize: "16px", fontWeight: "500", color: "#000000" }}>  Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum vestt vestibulum?</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div className="accordion-item pb-3" style={{ paddingLeft: '30px' }}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button">
                                        <div className='d-flex'>
                                            <div>
                                                <IoIosAddCircle className='circle_btn' />
                                            </div>
                                            <div>
                                                <div className='d-flex ms-2'>
                                                    <span style={{ textAlign: 'left', borderBottom: '1px solid #ccc', paddingBottom: '5px' }} >
                                                        <p className='accordion-para' style={{ fontSize: "16px", fontWeight: "500", color: "#000000" }}>  Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum vestt vestibulum?</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </h2>
                            </div>
                            <div className="accordion-item pb-3" style={{ paddingLeft: '30px' }}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button">
                                        <div className='d-flex'>
                                            <div>
                                                <IoIosAddCircle className='circle_btn' />
                                            </div>
                                            <div>
                                                <div className='d-flex ms-2'>
                                                    <span style={{ textAlign: 'left', borderBottom: '1px solid #ccc', paddingBottom: '5px' }} >
                                                        <p className='accordion-para' style={{ fontSize: "16px", fontWeight: "500", color: "#000000" }}>  Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum vestt vestibulum?</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </h2>
                            </div>
                            <div className="accordion-item pb-3" style={{ paddingLeft: '30px' }}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button">
                                        <div className='d-flex'>
                                            <div>
                                                <IoIosAddCircle className='circle_btn' />
                                            </div>
                                            <div>
                                                <div className='d-flex ms-2'>
                                                    <span className='FreqauntQuestionSpanStyle' style={{}} >
                                                        <p className='accordion-para'>  Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum vestt vestibulum?</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </h2>
                            </div>
                            <div className="accordion-item pb-3" style={{ paddingLeft: '30px' }}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button">
                                        <div className='d-flex'>
                                            <div>
                                                <IoIosAddCircle className='circle_btn' />
                                            </div>
                                            <div>
                                                <div className='d-flex ms-2'>
                                                    <span style={{ textAlign: 'left', borderBottom: '1px solid #ccc', paddingBottom: '5px' }} >
                                                        <p className='accordion-para'>  Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum vestt vestibulum?</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </h2>
                            </div>
                            <div className="accordion-item pb-3" style={{ paddingLeft: '30px' }}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button">
                                        <div className='d-flex'>
                                            <div>
                                                <IoIosAddCircle className='circle_btn' />
                                            </div>
                                            <div>
                                                <div className='d-flex ms-2'>
                                                    <span style={{ textAlign: 'left', borderBottom: '1px solid #ccc', paddingBottom: '5px' }} >
                                                        <p className='accordion-para'>  Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum vestt vestibulum?</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </h2>
                            </div>
                            <div className="accordion-item pb-3" style={{ paddingLeft: '30px' }}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button">
                                        <div className='d-flex'>
                                            <div>
                                                <IoIosAddCircle className='circle_btn' />
                                            </div>
                                            <div>
                                                <div className='d-flex ms-2'>
                                                    <span style={{ textAlign: 'left', borderBottom: '1px solid #ccc', paddingBottom: '5px' }} >
                                                        <p className='accordion-para'>  Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum vestt vestibulum?</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </h2>
                            </div>
                            <div className="accordion-item pb-3" style={{ paddingLeft: '30px' }}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button">
                                        <div className='d-flex'>
                                            <div>
                                                <IoIosAddCircle className='circle_btn' />
                                            </div>
                                            <div>
                                                <div className='d-flex ms-2'>
                                                    <span style={{ textAlign: 'left', borderBottom: '1px solid #ccc', paddingBottom: '5px' }} >
                                                        <p className='accordion-para'>  Lorem ipsum dolor sit amet consectetur. Amet ut risus egestas ut pretium vestibulum vestt vestibulum?</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='row mt-5 main_section'>
                    <h2 className='card-heading' style={{ maxWidth: "19ch" }}> Explore new insights on accountings</h2>
                    <div className='col-md-6'>

                    </div>
                </div>
                <div className="container">
                    <div className="row row-cols-1 row-cols-md-4 g-4 pt-3 justify-content-center">
                        {cardData.map((data) => (
                            <div className="col mb-4 card-hover" key={data.id} style={{ width: '350px' }}>
                                <div className="card h-100">
                                    <div className="cardimages">
                                        <img src={data.image} className="card-img-top p-3 image" alt="..." />
                                    </div>
                                    <div className="card-body">
                                        <h4 className="card-title">{data.title}</h4>
                                        <p className="card-text card-para">{data.para}</p>
                                        <hr />
                                        <div className="d-flex justify-content-center card-date ">
                                            <p style={{ font: "400 14px 'Roboto" }}>{data.date}</p>
                                            <p className='card-comments ps-md-4'>
                                                <MdModeComment className='card-icon' /> {data.comments}
                                            </p>
                                            <p className='card-comments'>
                                                <MdOutlineScreenShare className='card-icon' /> {data.shares}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <Link to='/blogs' style={{ textDecoration: "none" }}>
                    <div className='d-flex justify-content-center  pb-3 pt-4'>
                        <button className='card-btn btn border rounded'>Read our other blogs</button>
                    </div>
                </Link>
            </div>
            <Footer />
        </>

    );
}

export default Homepage;

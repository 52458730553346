import { ActionTypes } from "./actionTypes";

// userLogin //
export const userLoginRequest = (data)=>{
    return {
        type:ActionTypes.LOGIN_REQUEST,
        payload:data
    }
}
export const userLoginSuccess = (data)=>{
    return {
        type:ActionTypes.LOGIN_SUCCESS,
        payload:data
    }
}
export const userLoginFailure = (data)=>{
    return {
        type:ActionTypes.LOGIN_FAILURE,
        payload:data
    }
}

// forgotPassword //
export const forgotPasswordRequest = (data)=>{
    return {
        type:ActionTypes.FORGOT_PASSWORD_REQUEST,
        payload:data
    }
}
export const forgotPasswordSuccess = (data)=>{
    return {
        type:ActionTypes.FORGOT_PASSWORD_SUCCESS,
        payload:data
    }
}
export const forgotPasswordFailure = (data)=>{
    return {
        type:ActionTypes.FORGOT_PASSWORD_FAILURE,
        payload:data
    }
}

// createNewPassword //
export const createNewPasswordRequest = (data)=>{
    return{
        type:ActionTypes.CREATE_NEW_PASSWORD_REQUEST,
        payload:data
    }
}

export const createNewPasswordSuccess = (data)=>{
    return{
        type:ActionTypes.CREATE_NEW_PASSWORD_SUCCESS,
        payload:data
    }
}
export const createNewPasswordFailure = (data)=>{
    return{
        type:ActionTypes.CREATE_NEW_PASSWORD_FAILURE,
        payload:data
    }
}

export const updateClientPasswordRequest = (data)=>{
    return {
        type:ActionTypes.UPDATE_PASSWORD_REQUEST,
        payload:data
    }
}
export const updateClientPasswordSuccess = (data)=>{
    return {
        type:ActionTypes.UPDATE_PASSWORD_SUCCESS,
        payload:data
    }
}
export const updateClientPasswordFailure = (data)=>{
    return {
        type:ActionTypes.UPDATE_PASSWORD_FAILURE,
        payload:data
    }
}



export const userLogout = ()=>{
    return {
        type:ActionTypes.LOGOUT,
    }
}

// getCountry //
export const getCountryRequest = ()=>{
    return{
        type:ActionTypes.GET_COUNTRY_Request,
    }
}

export const getCountryFailure = ()=>{
    return{
        type:ActionTypes.GET_COUNTRY_Failure,
    }
}
export const getCountrySuccess = (data)=>{
    return{
        type:ActionTypes.GET_COUNTRY_Success,
        payload:data
    }
}


// postSignUp //
export const postSignUpRequest=(data)=>{
    return {
        type:ActionTypes.SIGN_UP_REQUEST,
        payload:data
    }
}

export const postSignUpSuccess=(data)=>{
    return {
        type:ActionTypes.SIGN_UP_SUCCESS,
        payload:data
    }
}

export const postSignUpFailure=(data)=>{
    return {
        type:ActionTypes.SIGN_UP_FAILURE,
        payload:data
    }
}

export const postVerifyEmailRequest=(data)=>{
    return {
        type:ActionTypes.VERIFY_EMAIL_REQUEST,
        payload:data
    }
}

export const postVerifyEmailSuccess=(data)=>{
    return {
        type:ActionTypes.VERIFY_EMAIL_SUCCESS,
        payload:data
    }
}

export const postVerifyEmailFailure=(error)=>{
    return {
        type:ActionTypes.VERIFY_EMAIL_FAILURE,
        payload:error
    }
}

export const logoutProfile = (data)=>{
    return {
        type:ActionTypes.LOGOUT,
        payload:data
    }
}

export const fetchApi = (data)=>{
    return {
        type:ActionTypes.FETCHAPI,
        payload:data
    }
}

export const fetchCostsApi = (data)=>{
    return {
        type:ActionTypes.FETCOSTSCHAPI,
        payload:data
    }
}
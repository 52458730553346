import React from 'react';
import './Dashboard.scss';
import lion from "./../../assets/images/lion.png";
import lastnames from "./../../assets/images/lastnames.png";
import Accountsetup from "../../assets/images/Accountsetup.png";
import InviteAccountant from "../../assets/images/InviteAccountant.png";
import { useNavigate } from 'react-router-dom';
import { logoutProfile } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';


const cardData = [
  {
    image: Accountsetup,
    path: '/dashboard/accountsetup',
    title: 'Account setup',
    text: 'Set up your account and unlock a world of possibilities. Click here to begin your journey!',
  },
  {
    image: InviteAccountant,
    path: '/inviteaccountant',
    title: 'Invite your accountant',
    text: 'Invite your client now and elevate financial management, to streamline teamwork and boost efficiency.',
  },

];
const DashboardInviteClient = () => {
  const log = useSelector((state) => state.logout);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(path);
  };
  if (log.logout == false) {
    dispatch(logoutProfile(true))
  }
  return (
    <div className='main_content'>
      <div className='dashboardInvite_main_container'>
        <div className='d-flex  client_container'>
          <div className='logo_container'>
            <img src={lion} alt="" className='lion_img' />
            <h3>Leo Coders Pvt. Ltd.</h3>
            <button className='show-btn'>Show</button>
          </div>

          <div className='logo_container_mile'>
            <img src={lastnames} alt="" className='lion_img' />
            <h3>The LastMilestone</h3>
            <button className='show-btn'>Show</button>
          </div>
        </div>

      </div>
      <hr style={{ color: "#D9D9D9" }} />

      <div className="center-div">
        <div className="d-md-flex equal-cards">
          {cardData.map((card, index) => (
            <div
              key={index}
              className="card text-center"
              onClick={() => handleCardClick(card.path)}
              style={{ transition: 'box-shadow 0.3s' }}
              onMouseOver={(e) => (e.currentTarget.style.boxShadow = '5px 5px 5px rgba(0, 0, 0, 0.1)')}
              onMouseOut={(e) => (e.currentTarget.style.boxShadow = 'none')}
            >
              <div className="card-body">
                <img src={card.image} alt='' className='CardsImageStyle' />
                <h5 className="card-title">{card.title}</h5>
                <p className="card-text" >{card.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>


    </div>
  );
}

export default DashboardInviteClient;


export const AllCountryDataFormatted = [
  {
    code: "AD",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ad.svg",
    label: "Andorra",
    phone: "376",
    phoneLength: 6,
  },
  {
    code: "AE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ae.svg",
    label: "United Arab Emirates",
    phone: "971",
    phoneLength: 9,
  },
  {
    code: "AF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/af.svg",
    label: "Afghanistan",
    phone: "93",
    phoneLength: 9,
  },
  {
    code: "AG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ag.svg",
    label: "Antigua and Barbuda",
    phone: "1-268",
    phoneLength: 10,
  },
  {
    code: "AI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ai.svg",
    label: "Anguilla",
    phone: "1-264",
    phoneLength: 10,
  },
  {
    code: "AL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/al.svg",
    label: "Albania",
    phone: "355",
    phoneLength: 9,
  },
  {
    code: "AM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/am.svg",
    label: "Armenia",
    phone: "374",
    phoneLength: 6,
  },
  {
    code: "AO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ao.svg",
    label: "Angola",
    phone: "244",
    phoneLength: 9,
  },
  {
    code: "AQ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/aq.svg",
    label: "Antarctica",
    phone: "672",
    phoneLength: 6,
  },
  {
    code: "AR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ar.svg",
    label: "Argentina",
    phone: "54",
    phoneLength: [6, 7, 8],
  },
  {
    code: "AS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/as.svg",
    label: "American Samoa",
    phone: "1-684",
    phoneLength: 10,
  },
  {
    code: "AT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/at.svg",
    label: "Austria",
    phone: "43",
    phoneLength: [10, 11],
  },
  {
    code: "AU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/au.svg",
    label: "Australia",
    phone: "61",
    phoneLength: 9,
    suggested: true,
  },
  {
    code: "AW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/aw.svg",
    label: "Aruba",
    phone: "297",
    phoneLength: 7,
  },
  {
    code: "AZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/az.svg",
    label: "Azerbaijan",
    phone: "994",
    phoneLength: 9,
  },
  {
    code: "BA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ba.svg",
    label: "Bosnia and Herzegovina",
    phone: "387",
    phoneLength: 8,
  },
  {
    code: "BB",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bb.svg",
    label: "Barbados",
    phone: "1-246",
    phoneLength: 10,
  },
  {
    code: "BD",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bd.svg",
    label: "Bangladesh",
    phone: "880",
    phoneLength: 10,
  },
  {
    code: "BE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/be.svg",
    label: "Belgium",
    phone: "32",
    phoneLength: 9,
  },
  {
    code: "BF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bf.svg",
    label: "Burkina Faso",
    phone: "226",
    phoneLength: 8,
  },
  {
    code: "BG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bg.svg",
    label: "Bulgaria",
    phone: "359",
    phoneLength: 9,
  },
  {
    code: "BH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bh.svg",
    label: "Bahrain",
    phone: "973",
    phoneLength: 8,
  },
  {
    code: "BI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bi.svg",
    label: "Burundi",
    phone: "257",
    phoneLength: 8,
  },
  {
    code: "BJ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bj.svg",
    label: "Benin",
    phone: "229",
    phoneLength: 8,
  },
  {
    code: "BL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bl.svg",
    label: "Saint Barthelemy",
    phone: "590",
    phoneLength: 9,
  },
  {
    code: "BM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bm.svg",
    label: "Bermuda",
    phone: "1-441",
    phoneLength: 10,
  },
  {
    code: "BN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bn.svg",
    label: "Brunei Darussalam",
    phone: "673",
    phoneLength: 7,
  },
  {
    code: "BO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bo.svg",
    label: "Bolivia",
    phone: "591",
    phoneLength: 9,
  },
  {
    code: "BR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/br.svg",
    label: "Brazil",
    phone: "55",
    phoneLength: 11,
  },
  {
    code: "BS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bs.svg",
    label: "Bahamas",
    phone: "1-242",
    phoneLength: 10,
  },
  {
    code: "BT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bt.svg",
    label: "Bhutan",
    phone: "975",
    phoneLength: 7,
  },
  {
    code: "BW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bw.svg",
    label: "Botswana",
    phone: "267",
    phoneLength: 7,
  },
  {
    code: "BY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/by.svg",
    label: "Belarus",
    phone: "375",
    phoneLength: 9,
  },
  {
    code: "BZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bz.svg",
    label: "Belize",
    phone: "501",
    phoneLength: 7,
  },
  {
    code: "CA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ca.svg",
    label: "Canada",
    phone: "1",
    phoneLength: 10,
    suggested: true,
  },
  {
    code: "CC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cc.svg",
    label: "Cocos (Keeling) Islands",
    phone: "61",
    phoneLength: 10,
  },
  {
    code: "CF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cf.svg",
    label: "Central African Republic",
    phone: "236",
    phoneLength: 8,
  },
  {
    code: "CH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ch.svg",
    label: "Switzerland",
    phone: "41",
    phoneLength: 9,
  },
  {
    code: "CK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ck.svg",
    label: "Cook Islands",
    phone: "682",
    phoneLength: 5,
  },
  {
    code: "CL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cl.svg",
    label: "Chile",
    phone: "56",
    phoneLength: 9,
  },
  {
    code: "CM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cm.svg",
    label: "Cameroon",
    phone: "237",
    phoneLength: 9,
  },
  {
    code: "CN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cn.svg",
    label: "China",
    phone: "86",
    phoneLength: 11,
  },
  {
    code: "CO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/co.svg",
    label: "Colombia",
    phone: "57",
    phoneLength: 10,
  },
  {
    code: "CR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cr.svg",
    label: "Costa Rica",
    phone: "506",
    phoneLength: 8,
  },
  {
    code: "CU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cu.svg",
    label: "Cuba",
    phone: "53",
    phoneLength: 8,
  },
  {
    code: "CV",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cv.svg",
    label: "Cape Verde",
    phone: "238",
    phoneLength: 7,
  },
  {
    code: "CX",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cx.svg",
    label: "Christmas Island",
    phone: "61",
    phoneLength: 9,
  },
  {
    code: "CY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cy.svg",
    label: "Cyprus",
    phone: "357",
    phoneLength: 8,
  },
  {
    code: "CZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cz.svg",
    label: "Czech Republic",
    phone: "420",
    phoneLength: 9,
  },
  {
    code: "DE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/de.svg",
    label: "Germany",
    phone: "49",
    phoneLength: 10,
    suggested: true,
  },
  {
    code: "DJ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/dj.svg",
    label: "Djibouti",
    phone: "253",
    phoneLength: 10,
  },
  {
    code: "DK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/dk.svg",
    label: "Denmark",
    phone: "45",
    phoneLength: 8,
  },
  {
    code: "DM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/dm.svg",
    label: "Dominica",
    phone: "1-767",
    phoneLength: 10,
  },
  {
    code: "DO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/do.svg",
    label: "Dominican Republic",
    phone: "1-809",
    phoneLength: 10,
  },
  {
    code: "DZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/dz.svg",
    label: "Algeria",
    phone: "213",
    phoneLength: 9,
  },
  {
    code: "EC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ec.svg",
    label: "Ecuador",
    phone: "593",
    phoneLength: 9,
  },
  {
    code: "EE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ee.svg",
    label: "Estonia",
    phone: "372",
    phoneLength: 8,
  },
  {
    code: "EG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/eg.svg",
    label: "Egypt",
    phone: "20",
    phoneLength: 10,
  },
  {
    code: "ER",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/er.svg",
    label: "Eritrea",
    phone: "291",
    phoneLength: 7,
  },
  {
    code: "ES",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/es.svg",
    label: "Spain",
    phone: "34",
    phoneLength: 9,
  },
  {
    code: "ET",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/et.svg",
    label: "Ethiopia",
    phone: "251",
    phoneLength: 9,
  },
  {
    code: "FI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/fi.svg",
    label: "Finland",
    max: 11,
    min: 9,
    phone: "358",
  },
  {
    code: "FJ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/fj.svg",
    label: "Fiji",
    phone: "679",
    phoneLength: 7,
  },
  {
    code: "FK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/fk.svg",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
    phoneLength: 5,
  },
  {
    code: "FO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/fo.svg",
    label: "Faroe Islands",
    phone: "298",
    phoneLength: 5,
  },
  {
    code: "FR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/fr.svg",
    label: "France",
    phone: "33",
    phoneLength: 9,
    suggested: true,
  },
  {
    code: "GA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ga.svg",
    label: "Gabon",
    phone: "241",
    phoneLength: 7,
  },
  {
    code: "GB",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gb.svg",
    label: "United Kingdom",
    phone: "25",
    phoneLength: 10,
  },
  {
    code: "GD",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gd.svg",
    label: "Grenada",
    phone: "1-473",
    phoneLength: 10,
  },
  {
    code: "GE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ge.svg",
    label: "Georgia",
    phone: "995",
    phoneLength: 9,
  },
  {
    code: "GF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gf.svg",
    label: "French Guiana",
    phone: "594",
    phoneLength: 9,
  },
  {
    code: "GG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gg.svg",
    label: "Guernsey",
    phone: "44",
    phoneLength: 10,
  },
  {
    code: "GH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gh.svg",
    label: "Ghana",
    phone: "233",
    phoneLength: 9,
  },
  {
    code: "GI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gi.svg",
    label: "Gibraltar",
    phone: "350",
    phoneLength: 8,
  },
  {
    code: "GL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gl.svg",
    label: "Greenland",
    phone: "299",
    phoneLength: 6,
  },
  {
    code: "GM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gm.svg",
    label: "Gambia",
    phone: "220",
    phoneLength: 7,
  },
  {
    code: "GN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gn.svg",
    label: "Guinea",
    phone: "224",
    phoneLength: 9,
  },
  {
    code: "GP",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gp.svg",
    label: "Guadeloupe",
    phone: "590",
    phoneLength: 9,
  },
  {
    code: "GQ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gq.svg",
    label: "Equatorial Guinea",
    phone: "240",
    phoneLength: 9,
  },
  {
    code: "GR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gr.svg",
    label: "Greece",
    phone: "30",
    phoneLength: 10,
  },
  {
    code: "GS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gs.svg",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
    phoneLength: 5,
  },
  {
    code: "GT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gt.svg",
    label: "Guatemala",
    phone: "502",
    phoneLength: 8,
  },
  {
    code: "GU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gu.svg",
    label: "Guam",
    phone: "1-671",
    phoneLength: 10,
  },
  {
    code: "GW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gw.svg",
    label: "Guinea-Bissau",
    phone: "245",
    phoneLength: 9,
  },
  {
    code: "GY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gy.svg",
    label: "Guyana",
    phone: "592",
    phoneLength: 7,
  },
  {
    code: "HK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/hk.svg",
    label: "Hong Kong",
    phone: "852",
    phoneLength: 8,
  },
  {
    code: "HN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/hn.svg",
    label: "Honduras",
    phone: "504",
    phoneLength: 8,
  },
  {
    code: "HR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/hr.svg",
    label: "Croatia",
    phone: "385",
    phoneLength: 9,
  },
  {
    code: "HT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ht.svg",
    label: "Haiti",
    phone: "509",
    phoneLength: 8,
  },
  {
    code: "HU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/hu.svg",
    label: "Hungary",
    phone: "36",
    phoneLength: 9,
  },
  {
    code: "ID",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/id.svg",
    label: "Indonesia",
    phone: "62",
    phoneLength: 11,
  },
  {
    code: "IE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ie.svg",
    label: "Ireland",
    phone: "353",
    phoneLength: 9,
  },
  {
    code: "IL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/il.svg",
    label: "Israel",
    phone: "972",
    phoneLength: 9,
  },
  {
    code: "IM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/im.svg",
    label: "Isle of Man",
    phone: "44",
    phoneLength: 10,
  },
  {
    code: "IN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/in.svg",
    label: "India",
    phone: "91",
    phoneLength: 10,
  },
  {
    code: "IO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/io.svg",
    label: "British Indian Ocean Territory",
    phone: "246",
    phoneLength: 7,
  },
  {
    code: "IQ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/iq.svg",
    label: "Iraq",
    phone: "964",
    phoneLength: 10,
  },
  {
    code: "IS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/is.svg",
    label: "Iceland",
    phone: "354",
    phoneLength: 7,
  },
  {
    code: "IT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/it.svg",
    label: "Italy",
    phone: "39",
    phoneLength: 10,
  },
  {
    code: "JE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/je.svg",
    label: "Jersey",
    phone: "44",
    phoneLength: 10,
  },
  {
    code: "JM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/jm.svg",
    label: "Jamaica",
    phone: "1-876",
    phoneLength: 10,
  },
  {
    code: "JO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/jo.svg",
    label: "Jordan",
    phone: "962",
    phoneLength: [8, 9],
  },
  {
    code: "JP",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/jp.svg",
    label: "Japan",
    phone: "322",
    suggested: true,
    phoneLength: 11,
  },
  {
    code: "KE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ke.svg",
    label: "Kenya",
    phone: "254",
    phoneLength: 10,
  },
  {
    code: "KG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/kg.svg",
    label: "Kyrgyzstan",
    phone: "996",
    phoneLength: 9,
  },
  {
    code: "KH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/kh.svg",
    label: "Cambodia",
    phone: "855",
    phoneLength: 9,
  },
  {
    code: "KI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ki.svg",
    label: "Kiribati",
    phone: "686",
    phoneLength: 8,
  },
  {
    code: "KM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/km.svg",
    label: "Comoros",
    phone: "269",
    phoneLength: 7,
  },
  {
    code: "KN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/kn.svg",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
    phoneLength: 10,
  },
  {
    code: "KW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/kw.svg",
    label: "Kuwait",
    phone: "965",
    phoneLength: 8,
  },
  {
    code: "KY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ky.svg",
    label: "Cayman Islands",
    phone: "1-345",
    phoneLength: 7,
  },
  {
    code: "KZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/kz.svg",
    label: "Kazakhstan",
    phone: "7",
    phoneLength: 10,
  },
  {
    code: "LB",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/lb.svg",
    label: "Lebanon",
    phone: "961",
    phoneLength: [7, 8],
  },
  {
    code: "LC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/lc.svg",
    label: "Saint Lucia",
    phone: "1-758",
    phoneLength: 7,
  },
  {
    code: "LI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/li.svg",
    label: "Liechtenstein",
    phone: "423",
    phoneLength: 7,
  },
  {
    code: "LK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/lk.svg",
    label: "Sri Lanka",
    phone: "94",
    phoneLength: 7,
  },
  {
    code: "LR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/lr.svg",
    label: "Liberia",
    phone: "231",
    phoneLength: [8, 9],
  },
  {
    code: "LS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ls.svg",
    label: "Lesotho",
    phone: "266",
    phoneLength: 8,
  },
  {
    code: "LT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/lt.svg",
    label: "Lithuania",
    phone: "370",
    phoneLength: 8,
  },
  {
    code: "LU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/lu.svg",
    label: "Luxembourg",
    phone: "352",
    phoneLength: 9,
  },
  {
    code: "LV",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/lv.svg",
    label: "Latvia",
    phone: "371",
    phoneLength: 8,
  },
  {
    code: "LY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ly.svg",
    label: "Libya",
    phone: "218",
    phoneLength: 10,
  },
  {
    code: "MA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ma.svg",
    label: "Morocco",
    phone: "212",
    phoneLength: 9,
  },
  {
    code: "MC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mc.svg",
    label: "Monaco",
    phone: "377",
    phoneLength: 8,
  },
  {
    code: "ME",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/me.svg",
    label: "Montenegro",
    phone: "382",
    phoneLength: 8,
  },
  {
    code: "MG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mg.svg",
    label: "Madagascar",
    phone: "261",
    phoneLength: 7,
  },
  {
    code: "MH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mh.svg",
    label: "Marshall Islands",
    phone: "692",
    phoneLength: 7,
  },
  {
    code: "ML",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ml.svg",
    label: "Mali",
    phone: "223",
    phoneLength: 8,
  },
  {
    code: "MM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mm.svg",
    label: "Myanmar",
    max: 10,
    min: 7,
    phone: "95",
  },
  {
    code: "MN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mn.svg",
    label: "Mongolia",
    phone: "976",
    phoneLength: 8,
  },
  {
    code: "MP",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mp.svg",
    label: "Northern Mariana Islands",
    phone: "1-670",
    phoneLength: 7,
  },
  {
    code: "MQ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mq.svg",
    label: "Martinique",
    phone: "596",
    phoneLength: 9,
  },
  {
    code: "MR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mr.svg",
    label: "Mauritania",
    phone: "222",
    phoneLength: 8,
  },
  {
    code: "MS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ms.svg",
    label: "Montserrat",
    phone: "1-664",
    phoneLength: 10,
  },
  {
    code: "MT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mt.svg",
    label: "Malta",
    phone: "356",
    phoneLength: 8,
  },
  {
    code: "MU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mu.svg",
    label: "Mauritius",
    phone: "230",
    phoneLength: 8,
  },
  {
    code: "MV",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mv.svg",
    label: "Maldives",
    phone: "960",
    phoneLength: 7,
  },
  {
    code: "MW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mw.svg",
    label: "Malawi",
    phone: "265",
    phoneLength: [7, 8, 9],
  },
  {
    code: "MX",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mx.svg",
    label: "Mexico",
    phone: "52",
    phoneLength: 10,
  },
  {
    code: "MY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/my.svg",
    label: "Malaysia",
    phone: "60",
    phoneLength: 7,
  },
  {
    code: "MZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mz.svg",
    label: "Mozambique",
    phone: "258",
    phoneLength: 12,
  },
  {
    code: "NA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/na.svg",
    label: "Namibia",
    phone: "264",
    phoneLength: 7,
  },
  {
    code: "NC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/nc.svg",
    label: "New Caledonia",
    phone: "687",
    phoneLength: 6,
  },
  {
    code: "NE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ne.svg",
    label: "Niger",
    phone: "227",
    phoneLength: 8,
  },
  {
    code: "NF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/nf.svg",
    label: "Norfolk Island",
    phone: "672",
    phoneLength: 6,
  },
  {
    code: "NG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ng.svg",
    label: "Nigeria",
    phone: "234",
    phoneLength: 8,
  },
  {
    code: "NI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ni.svg",
    label: "Nicaragua",
    phone: "505",
    phoneLength: 8,
  },
  {
    code: "NL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/nl.svg",
    label: "Netherlands",
    phone: "31",
    phoneLength: 9,
  },
  {
    code: "NO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/no.svg",
    label: "Norway",
    phone: "47",
    phoneLength: 8,
  },
  {
    code: "NP",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/np.svg",
    label: "Nepal",
    phone: "977",
    phoneLength: 10,
  },
  {
    code: "NR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/nr.svg",
    label: "Nauru",
    phone: "674",
    phoneLength: 7,
  },
  {
    code: "NU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/nu.svg",
    label: "Niue",
    phone: "683",
    phoneLength: 4,
  },
  {
    code: "NZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/nz.svg",
    label: "New Zealand",
    phone: "64",
    phoneLength: [8, 9],
  },
  {
    code: "OM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/om.svg",
    label: "Oman",
    phone: "968",
    phoneLength: 8,
  },
  {
    code: "PA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pa.svg",
    label: "Panama",
    phone: "507",
    phoneLength: 8,
  },
  {
    code: "PE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pe.svg",
    label: "Peru",
    phone: "51",
    phoneLength: 9,
  },
  {
    code: "PF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pf.svg",
    label: "French Polynesia",
    phone: "689",
    phoneLength: 8,
  },
  {
    code: "PG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pg.svg",
    label: "Papua New Guinea",
    phone: "675",
    phoneLength: 8,
  },
  {
    code: "PH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ph.svg",
    label: "Philippines",
    phone: "63",
    phoneLength: 10,
  },
  {
    code: "PK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pk.svg",
    label: "Pakistan",
    phone: "92",
    phoneLength: 10,
  },
  {
    code: "PL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pl.svg",
    label: "Poland",
    phone: "48",
    phoneLength: 9,
  },
  {
    code: "PM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pm.svg",
    label: "Saint Pierre and Miquelon",
    phone: "508",
    phoneLength: 6,
  },
  {
    code: "PN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pn.svg",
    label: "Pitcairn",
    phone: "870",
    phoneLength: 9,
  },
  {
    code: "PR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pr.svg",
    label: "Puerto Rico",
    phone: "1",
    phoneLength: 10,
  },
  {
    code: "PT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pt.svg",
    label: "Portugal",
    phone: "351",
    phoneLength: 9,
  },
  {
    code: "PW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pw.svg",
    label: "Palau",
    phone: "680",
    phoneLength: 7,
  },
  {
    code: "PY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/py.svg",
    label: "Paraguay",
    phone: "595",
    phoneLength: 9,
  },
  {
    code: "QA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/qa.svg",
    label: "Qatar",
    phone: "974",
    phoneLength: 8,
  },
  {
    code: "RE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/re.svg",
    label: "Reunion",
    phone: "262",
    phoneLength: 10,
  },
  {
    code: "RO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ro.svg",
    label: "Romania",
    phone: "40",
    phoneLength: 10,
  },
  {
    code: "RS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/rs.svg",
    label: "Serbia",
    phone: "381",
    phoneLength: 9,
  },
  {
    code: "RW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/rw.svg",
    label: "Rwanda",
    phone: "250",
    phoneLength: 9,
  },
  {
    code: "SA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sa.svg",
    label: "Saudi Arabia",
    phone: "966",
    phoneLength: 9,
  },
  {
    code: "SB",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sb.svg",
    label: "Solomon Islands",
    phone: "677",
    phoneLength: 7,
  },
  {
    code: "SC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sc.svg",
    label: "Seychelles",
    phone: "248",
    phoneLength: 7,
  },
  {
    code: "SD",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sd.svg",
    label: "Sudan",
    phone: "249",
    phoneLength: 7,
  },
  {
    code: "SE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/se.svg",
    label: "Sweden",
    phone: "46",
    phoneLength: 7,
  },
  {
    code: "SG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sg.svg",
    label: "Singapore",
    phone: "65",
    phoneLength: 8,
  },
  {
    code: "SI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/si.svg",
    label: "Slovenia",
    phone: "386",
    phoneLength: 9,
  },
  {
    code: "SJ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sj.svg",
    label: "Svalbard and Jan Mayen",
    phone: "47",
    phoneLength: 8,
  },
  {
    code: "SK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sk.svg",
    label: "Slovakia",
    phone: "421",
    phoneLength: 9,
  },
  {
    code: "SL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sl.svg",
    label: "Sierra Leone",
    phone: "232",
    phoneLength: 8,
  },
  {
    code: "SM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sm.svg",
    label: "San Marino",
    phone: "378",
    phoneLength: 10,
  },
  {
    code: "SN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sn.svg",
    label: "Senegal",
    phone: "221",
    phoneLength: 9,
  },
  {
    code: "SO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/so.svg",
    label: "Somalia",
    phone: "252",
    phoneLength: [8, 9],
  },
  {
    code: "SR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sr.svg",
    label: "Suriname",
    phone: "597",
    phoneLength: [6, 7],
  },
  {
    code: "SS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ss.svg",
    label: "South Sudan",
    phone: "211",
    phoneLength: 7,
  },
  {
    code: "ST",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/st.svg",
    label: "Sao Tome and Principe",
    phone: "239",
    phoneLength: 7,
  },
  {
    code: "SV",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sv.svg",
    label: "El Salvador",
    phone: "503",
    phoneLength: 8,
  },
  {
    code: "SY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sy.svg",
    label: "Syrian Arab Republic",
    phone: "963",
    phoneLength: 7,
  },
  {
    code: "TC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tc.svg",
    label: "Turks and Caicos Islands",
    phone: "1-649",
    phoneLength: 10,
  },
  {
    code: "TD",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/td.svg",
    label: "Chad",
    phone: "235",
    phoneLength: 6,
  },
  {
    code: "TG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tg.svg",
    label: "Togo",
    phone: "228",
    phoneLength: 8,
  },
  {
    code: "TH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/th.svg",
    label: "Thailand",
    phone: "66",
    phoneLength: 9,
  },
  {
    code: "TJ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tj.svg",
    label: "Tajikistan",
    phone: "992",
    phoneLength: 9,
  },
  {
    code: "TK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tk.svg",
    label: "Tokelau",
    phone: "690",
    phoneLength: 5,
  },
  {
    code: "TL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tl.svg",
    label: "Timor-Leste",
    phone: "670",
    phoneLength: 7,
  },
  {
    code: "TM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tm.svg",
    label: "Turkmenistan",
    phone: "993",
    phoneLength: 8,
  },
  {
    code: "TN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tn.svg",
    label: "Tunisia",
    phone: "216",
    phoneLength: 8,
  },
  {
    code: "TO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/to.svg",
    label: "Tonga",
    phone: "676",
    phoneLength: 5,
  },
  {
    code: "TR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tr.svg",
    label: "Turkey",
    phone: "90",
    phoneLength: 11,
  },
  {
    code: "TT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tt.svg",
    label: "Trinidad and Tobago",
    phone: "1-868",
    phoneLength: 7,
  },
  {
    code: "TV",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tv.svg",
    label: "Tuvalu",
    phone: "688",
    phoneLength: 5,
  },
  {
    code: "UA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ua.svg",
    label: "Ukraine",
    phone: "380",
    phoneLength: 9,
  },
  {
    code: "UG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ug.svg",
    label: "Uganda",
    phone: "256",
    phoneLength: 7,
  },
  {
    code: "US",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/us.svg",
    label: "United States",
    phone: "1",
    phoneLength: 10,
    suggested: true,
  },
  {
    code: "UY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/uy.svg",
    label: "Uruguay",
    phone: "598",
    phoneLength: 8,
  },
  {
    code: "UZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/uz.svg",
    label: "Uzbekistan",
    phone: "998",
    phoneLength: 9,
  },
  {
    code: "VA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/va.svg",
    label: "Holy See (Vatican City State)",
    phone: "379",
    phoneLength: 10,
  },
  {
    code: "VC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/vc.svg",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
    phoneLength: 7,
  },
  {
    code: "VN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/vn.svg",
    label: "Vietnam",
    phone: "84",
    phoneLength: 9,
  },
  {
    code: "VU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/vu.svg",
    label: "Vanuatu",
    phone: "678",
    phoneLength: 5,
  },
  {
    code: "WF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/wf.svg",
    label: "Wallis and Futuna",
    phone: "681",
    phoneLength: 6,
  },
  {
    code: "WS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ws.svg",
    label: "Samoa",
    phone: "685",
    phoneLength: [5, 6, 7],
  },
  {
    code: "XK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/xk.svg",
    label: "Kosovo",
    phone: "383",
    phoneLength: 8,
  },
  {
    code: "YE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ye.svg",
    label: "Yemen",
    phone: "967",
    phoneLength: 9,
  },
  {
    code: "YT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/yt.svg",
    label: "Mayotte",
    phone: "262",
    phoneLength: 9,
  },
  {
    code: "ZA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/za.svg",
    label: "South Africa",
    phone: "27",
    phoneLength: 9,
  },
  {
    code: "ZM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/zm.svg",
    label: "Zambia",
    phone: "260",
    phoneLength: 9,
  },
  {
    code: "ZW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/zw.svg",
    label: "Zimbabwe",
    phone: "263",
    phoneLength: 9,
  },
  {
    code: "AX",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ax.svg",
    label: "Alland Islands",
    max: 10,
    min: 7,
    phone: "358",
  },
  {
    code: "BV",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bv.svg",
    label: "Bouvet Island",
    phone: "47",
    phoneLength: 10,
  },
  {
    code: "CD",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cd.svg",
    label: "Congo, Democratic Republic of the",
    phone: "243",
    phoneLength: 7,
  },
  {
    code: "CG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cg.svg",
    label: "Congo, Republic of the",
    phone: "242",
    phoneLength: 9,
  },
  {
    code: "CI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cg.svg",
    label: "Cote d'Ivoire",
    phone: "225",
    phoneLength: 8,
  },
  {
    code: "CW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ci.svg",
    label: "Curacao",
    phone: "599",
    phoneLength: 7,
  },
  {
    code: "EH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cw.svg",
    label: "Western Sahara",
    phone: "212",
    phoneLength: 9,
  },
  {
    code: "FM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/fm.svg",
    label: "Micronesia, Federated States of",
    phone: "691",
    phoneLength: 7,
  },
  {
    code: "HM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/hm.svg",
    label: "Heard Island and McDonald Islands",
    phone: "672",
    phoneLength: 10,
  },
  {
    code: "IR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ir.svg",
    label: "Iran, Islamic Republic of",
    phone: "98",
    phoneLength: 11,
  },
  {
    code: "KP",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/kp.svg",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
    phoneLength: [4, 6, 7, 13],
  },
  {
    code: "KR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/kr.svg",
    label: "Korea, Republic of",
    phone: "82",
    phoneLength: [7, 8],
  },
  {
    code: "LA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/la.svg",
    label: "Lao People's Democratic Republic",
    phone: "856",
    phoneLength: [8, 9],
  },
  {
    code: "MD",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/md.svg",
    label: "Moldova, Republic of",
    phone: "373",
    phoneLength: 8,
  },
  {
    code: "MF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mf.svg",
    label: "Saint Martin (French part",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sz.svg",
    label: "Swaziland",
    phone: "268",
    phoneLength: 8,
  },
  {
    code: "TF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tf.svg",
    label: "French Southern Territories",
    phone: "262",
    phoneLength: 10,
  },
  {
    code: "TW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tw.svg",
    label: "Taiwan, Province of China",
    phone: "886",
    phoneLength: 9,
  },
  {
    code: "TZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tz.svg",
    label: "United Republic of Tanzania",
    phone: "255",
    phoneLength: 7,
  },
  {
    code: "VE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ve.svg",
    label: "Venezuela",
    phone: "58",
    phoneLength: 7,
  },
  {
    code: "VG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/vg.svg",
    label: "British Virgin Islands",
    phone: "1-284",
    phoneLength: 7,
  },
  {
    code: "VI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/vi.svg",
    label: "US Virgin Islands",
    phone: "1-340",
    phoneLength: 10,
  },
];



import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    postVerifyEmailFailure,
} from "../../redux/actions";

import { BASE_URL } from "../../constant/common";
import { useSearchParams, useNavigate } from 'react-router-dom';
import _FetchApi from "../../services/fetchservice";
import { toast } from "react-toastify";


const VerifyMail = () => {
    const [showMessage, setShowMessage] = useState()
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()


    const verifyEmail = async () => {
        try {
            const response = await _FetchApi('POST', `${BASE_URL}verifyemail/?code=${token}`);
            if (response.data.code === 400) {
                toast.error(response.data.message)
            } else {
                let message = response?.data?.message
                let token = response?.data?.message?.token?.access
                let id = response?.data?.message?.user_data?.id
                let userName = response?.data?.message?.user_profile?.first_name
                let userLastName = response?.data?.message?.user_profile?.last_name
                let email = response?.data?.message?.user_profile?.email
                let phone_number = response?.data?.message?.user_profile?.phone_number
                let country_code = response?.data?.message?.user_profile?.country_id?.country_code
                let country_flag = response?.data?.message?.user_profile?.country_id?.country_flag
                let country_name = response?.data?.message?.user_profile?.country_id?.country_name
                let created_date = response?.data?.message?.user_profile?.country_id?.created_date
                let last_updated_date = response?.data?.message?.user_profile?.country_id?.last_updated_date
                let Organisation = response?.data?.message?.user_data?.Tbl_Organization_M_id?.organization_domain
                let userType = response?.data?.message?.user_data.Tbl_User_Type_id

                if (token) {
                    localStorage.setItem('message', JSON.stringify(message))
                    localStorage.setItem('access', token)
                    localStorage.setItem('id', id)
                    localStorage.setItem('userName', userName)
                    localStorage.setItem('userLastName', userLastName)
                    localStorage.setItem('email', email)
                    localStorage.setItem('phone_number', phone_number)
                    localStorage.setItem('country_code', country_code)
                    localStorage.setItem('country_name', country_name)
                    localStorage.setItem('created_date', created_date)
                    localStorage.setItem('last_updated_date', last_updated_date)
                    localStorage.setItem('country_flag', country_flag)
                    localStorage.setItem('Organisation', Organisation)
                    localStorage.setItem('user_type', JSON.stringify(userType))
                    navigate('/accountant-dashboard')
                }
            }
        } catch (err) {
            dispatch(postVerifyEmailFailure("An error occurred while verifying your email."));
        }
    }

    const token = searchParams.get('code');
    const dispatch = useDispatch();
    const { loading, error } = useSelector((state) => state.verifyEmail);


    useEffect(() => {
        if (!token) {
            setShowMessage("Invalid or missing token.", "error");
            return;
        } else {
            verifyEmail()
        }
    }, [token]);

    if (loading) {
        return <h5>Loading...</h5>;
    }
    if (error) {
        return <div>Error: {error}</div>;
    }
    return null;
}

export default VerifyMail;

